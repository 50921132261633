import { Divider, Spacing } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconExpandLessRegular } from '@seed-design/icon';
import { useMemo } from 'react';

import MemberMissionBanner from '@/components/common/MemberMissionBanner';
import MemberMissionList from '@/components/common/MemberMissionBanner/MemberMissionList';
import { useStorage } from '@/hooks/useStorage';

import useMemberMissionList, { MemberMission } from '../../hooks/useMemberMissionList';

type Props = {
  groupId: string;
};

const GroupDetailOnboardingBannerForMember = ({ groupId }: Props) => {
  const missionList = useMemberMissionList(groupId);
  if (missionList.length === 0) return <Divider padding={0} />;

  return (
    <>
      <GroupDetailOnboardingBannerForMemberCore missionList={missionList} />
      <Spacing height={4} />
    </>
  );
};

type CoreProps = {
  missionList: MemberMission[];
};

const GroupDetailOnboardingBannerForMemberCore = ({ missionList }: CoreProps) => {
  const storageKey = 'closedMemberOnboardingBanner';
  const [isBannerClosed, setIsBannerClosed] = useStorage<typeof storageKey>(storageKey, false);
  const title = useMemo(() => {
    const defaultTitle = '모임 가입 미션';
    if (!isBannerClosed) return defaultTitle;

    const mission = missionList.find(({ isCompleted }) => !isCompleted);
    return mission?.title ?? defaultTitle;
  }, [isBannerClosed, missionList]);

  const progress = useMemo(() => {
    const completedMissionCount = missionList.filter(({ isCompleted }) => isCompleted).length;
    const totalMissionCount = missionList.length;
    const value = (completedMissionCount / totalMissionCount) * 100;

    return Math.round(value / 10) * 10;
  }, [missionList]);

  const handleBannerClick = () => {
    if (!isBannerClosed) return;
    const mission = missionList.find(({ isCompleted }) => !isCompleted);
    mission?.onClick();
  };

  const handleToggleButtonClick = (e) => {
    e.stopPropagation();
    setIsBannerClosed(!isBannerClosed);
  };

  return (
    <MemberMissionBanner
      title={title}
      progress={progress}
      size="large"
      onClick={handleBannerClick}
      actionButton={
        <button
          style={{
            transform: isBannerClosed ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 200ms ease-in-out',
          }}
          onClick={handleToggleButtonClick}
        >
          <IconExpandLessRegular size={20} color={vars.$scale.color.gray600} />
        </button>
      }
    >
      <MemberMissionList missionList={missionList} opened={!isBannerClosed} />
    </MemberMissionBanner>
  );
};

export default GroupDetailOnboardingBannerForMember;
