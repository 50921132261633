import { GroupCurrentUser, GroupDetailPresentation } from '@community-group/api/lib/group/models';
import { ViewLoader, withAsyncBoundary } from '@community-group/components';
import { useMemo } from 'react';

import { useGetApplications } from '@/api/hooks/useGetApplications';
import { useGetGroupFeedReviewWriteableMeetup } from '@/api/hooks/useGetGroupFeedReviewWriteableMeetup';

import GroupDetailMeetupReviewBanner from './GroupDetailMeetupReviewBanner';
import GroupDetailOnboardingBanner from './GroupDetailOnboardingBanner';
import GroupDetailWaitingMemberBanner from './GroupDetailWaitingMemberBanner';

type Props = {
  group: GroupDetailPresentation;
  currentUser: GroupCurrentUser;
};

const GroupDetailMainBanner = ({ group, currentUser }: Props) => {
  const groupId = useMemo(() => group.id.toString(), [group.id]);

  const { data: applications } = useGetApplications(groupId);
  const { data: reviewWriteable } = useGetGroupFeedReviewWriteableMeetup(groupId);

  const isShowApprovalBanner = useMemo(() => {
    if (!currentUser.permissions.applyApplicationMember) return false;
    if (group.management?.applicationCount === undefined) return false;
    if (group.management?.applicationCount <= 0) return false;

    return true;
  }, [group, currentUser]);

  if (isShowApprovalBanner) {
    // NOTE: isShowApprovalBanner 조건에 의해 검사된 로직이므로 항상 applications가 존재
    const memberCount = applications?.pages[0].data.applicationCount ?? 0;

    return (
      <GroupDetailWaitingMemberBanner
        group={group}
        currentUser={currentUser}
        memberCount={memberCount}
      />
    );
  }

  if (reviewWriteable?.meetupId) {
    const meetupId = reviewWriteable?.meetupId.toString();

    return (
      <GroupDetailMeetupReviewBanner group={group} currentUser={currentUser} meetupId={meetupId} />
    );
  }

  return <GroupDetailOnboardingBanner group={group} currentUser={currentUser} />;
};

export default withAsyncBoundary(GroupDetailMainBanner, {
  pendingFallback: <ViewLoader />,
  rejectedFallback: <></>,
});
