import { useUserConfig } from '@/contexts/UserConfig';
import { isExposeInternalUser } from '@/utils/check';

import { useFeatureFlag } from './useFeatureFlag';

// Upload video 기능 활성화 여부를 반환하는 hook
// feature flag useVideoUpload 또는 내부 사용자인 경우에만 true를 반환
function useVideoUploadFeatureFlag() {
  const useVideoUpload = useFeatureFlag('useVideoUpload');
  const { userConfig } = useUserConfig();
  const isInternalUser = userConfig.userId
    ? isExposeInternalUser(userConfig.userId.toString(), false)
    : false;

  return useVideoUpload || isInternalUser;
}

export default useVideoUploadFeatureFlag;
