import { VerticalSpacer } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconVideoRegular } from '@seed-design/icon';

import { VerticalSpacing } from '@/components/common/Spacing';
import { trackEvent } from '@/utils/analytics';

import useHandleUploadVideo from '../../hooks/useHandleUploadVideo';
import { GroupPostFormProps } from '../GroupPostForm';
import * as s from './index.css';

type Props = {
  formHandler: GroupPostFormProps['formHandler'];
  groupId: string;
  closeBottomSheet: () => Promise<void>;
};

const NEW_BADGE_IMG =
  'https://assetstorage.krrt.io/1025317940251365954/21405f71-d6fa-4270-9b7d-3fb985a7de3c/width=48,height=48.png';

function StartBottomSheetVideoUploadButton({ formHandler, groupId, closeBottomSheet }: Props) {
  const { handleVideoFileSelection } = useHandleUploadVideo({
    formHandler,
  });

  const onVideoFileSelection = () => {
    trackEvent({
      event: 'click_group_post_video_button',
      params: {
        buttonLocation: 'startbottomsheet',
        groupId,
      },
    });

    handleVideoFileSelection();
    closeBottomSheet();
  };

  return (
    <button className={s.VideoItem} onClick={onVideoFileSelection}>
      <IconVideoRegular size={24} color={vars.$scale.color.gray900} />
      <VerticalSpacer size={12} />
      <p>동영상</p>
      <VerticalSpacer size={4} />
      <img src={NEW_BADGE_IMG} alt="new-service" width={16} height={16} />
    </button>
  );
}

export default StartBottomSheetVideoUploadButton;
