import { Dialog, useDialog } from '@community-group/components';

import { useDeleteApplication } from '@/api/hooks/useDeleteApplication';
import { useGetEventLogMyInfo } from '@/api/hooks/useGetEventLogMyInfo';
import { useGetGroupMe } from '@/api/hooks/useGetGroupMe';
import { useGetMe } from '@/api/hooks/useGetMe';
import { usePostApplication } from '@/api/hooks/usePostApplication';
import { usePostJoinGroup } from '@/api/hooks/usePostJoinGroup';
import {
  JoinGroupStateProps,
  useJoinGroupState,
} from '@/components/group/JoinGroupState/hooks/useJoinGroupState';
import useJoinGroupHuggiesMarketingPromotion from '@/components/group/MarketingPromotion/hooks/useJoinGroupHuggiesMarketingPromotion';
import { useHandleVerificationBottomSheet } from '@/components/group/new/Verification/hooks/useHandleVerificationBottomSheet';
import { openVerifyNeighborhood } from '@/constants/path';
import { useBridge } from '@/contexts/Bridge';
import { useUserConfig } from '@/contexts/UserConfig';
import { useFlow } from '@/stackflow';
import { useStore } from '@/store';
import { MODAL_KEY } from '@/store/modal/modalSliceStore';
import { extendAppsflyerLoggerType, trackEvent } from '@/utils/analytics';
import { GroupEventParams } from '@/utils/analytics/type';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';
import { shortening } from '@/utils/text';

import useCheckGroupProfileCreated from './useCheckGroupProfileCreated';
import { useHandleErrorWithToast } from './useHandleErrorWithToast';

type Props = JoinGroupStateProps & {
  buttonText?: string;
  successCallback?: () => void;
  // utm외에 어떤 모임서비스 내에서 가입했는지 확인하기 위한 referrerPage
  referrerPage?: 'group_detail' | 'group_profile' | 'group_post_detail';
};

export const useJoinGroup = ({
  groupId,
  buttonText = '모임 가입하기',
  successCallback,
  referrerPage,
}: Props) => {
  const { push, pop } = useFlow();

  const { open: openDialog, close: closeDialog } = useDialog();
  const handleErrorWithToast = useHandleErrorWithToast();

  const { setOpenWebviewModal, setRecommendGroup } = useStore();

  const { refetch: refetchGroupMe } = useGetGroupMe(groupId);
  const { data: userMe } = useGetMe();
  const { groupInfo, userState, joinApprovalRequireReason, approvalConditions } = useJoinGroupState(
    { groupId }
  );

  const { handleVerificationBottomSheet } = useHandleVerificationBottomSheet();

  const { state: profileState } = useCheckGroupProfileCreated({});

  const isAppliedGroup = userState === 'applied';
  const hasQuestion =
    approvalConditions === 'optional_answer' || approvalConditions === 'require_answer';

  const { data: eventMyInfo } = useGetEventLogMyInfo();

  const { bridge } = useBridge();
  const { userConfig } = useUserConfig();
  const trackJoinGroupEvent = () => {
    const isFirstJoinGroup = !eventMyInfo?.data.isJoinedGroup;

    const eventParams: GroupEventParams = {
      joinType: groupInfo.joinType,
      groupId: groupInfo.id,
      groupName: groupInfo.name,
      groupCategory: groupInfo.categoryName,
      checkinReigionId: userConfig.regionId ?? '',
      checkinMostAccurate: userConfig.regionTownName ?? '',
      isInstantMeetupJoin: false,
      joinApprovalRequireReason,
      needVerification: groupInfo.needVerification,
      canViewVerifiedUserInfo: groupInfo.canViewVerifiedUserInfo,
      watched: groupInfo.watched,
      isFirstJoinGroup,
      isChatRequired: !groupInfo.isShowChatRoomSetting,
      isChatActivated: !groupInfo.isDeactivateChatRoom,
      from: referrerPage,
    };

    trackEvent({
      event: 'click_join_group',
      params: eventParams,
      loggerType: extendAppsflyerLoggerType,
    });

    if (isFirstJoinGroup) {
      trackEvent({
        event: 'click_first_join_group',
        params: eventParams,
        loggerType: ['APPSFLYER'],
      });
    }
  };

  //TODO: 10월 하기스 매직팬티 프로모션 가입 본 마케팅 프로모션
  const { handleOpenJoinHuggiesMarketingPromotionBottomSheet } =
    useJoinGroupHuggiesMarketingPromotion({
      groupId,
    });

  const { mutate: joinMutate } = usePostJoinGroup({
    groupId,
    onError: (error) => handleErrorWithToast(error),
    onSuccess: async () => {
      trackJoinGroupEvent();

      refetchGroupMe();
      refetchGroupDetail({ groupId });

      bridge.openToast({
        toast: {
          body: `'${shortening(groupInfo?.name, 14)}' 모임에 가입했어요.`,
        },
      });
      handleOpenJoinHuggiesMarketingPromotionBottomSheet();
    },
  });

  const { mutate: applicationMutate } = usePostApplication({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: async (data) => {
      trackJoinGroupEvent();

      if (data.data.application.recommendedGroups) {
        await setRecommendGroup(data.data.application.recommendedGroups);
        setOpenWebviewModal(MODAL_KEY.RECOMMEND_3_OTHER_GROUPS, true);
      }

      refetchGroupDetail({ groupId });

      if (hasQuestion) {
        pop();
      }
      handleOpenJoinHuggiesMarketingPromotionBottomSheet();
    },
  });

  const { mutate: deleteApplicationMutate } = useDeleteApplication({
    onError: (error) => handleErrorWithToast(error),
    onSuccess: () => {
      refetchGroupDetail({ groupId });
      refetchGroupMe();
      bridge.openToast({
        toast: {
          body: '가입 신청을 취소했어요.',
        },
      });
    },
  });

  const handleJoinCancel = () => {
    openDialog({
      element: (
        <Dialog
          title="가입 신청 취소"
          description="가입 신청을 그만둘까요?"
          primaryActionLabel="그만두기"
          secondaryActionLabel="취소"
          onSecondaryAction={closeDialog}
          onPrimaryAction={async () => {
            await closeDialog();
            await deleteApplicationMutate(Number(groupId));
          }}
        />
      ),
    });
  };
  const handleJoinGroup = async () => {
    if (joinApprovalRequireReason === 'not_certificated_region') {
      openDialog({
        element: (
          <Dialog
            title="동네인증"
            description={`모임에 가입하려면 ${groupInfo.regionName} 동네인증이 필요해요.`}
            primaryActionLabel="인증"
            onPrimaryAction={async () => {
              await closeDialog();

              trackEvent({ event: 'click_verify_neighboorhood' });
              openVerifyNeighborhood();
            }}
            secondaryActionIntent="nonpreferred"
            secondaryActionLabel="취소"
            onSecondaryAction={async () => {
              trackEvent({ event: 'click_cancel_verify_neighboorhood' });
              await closeDialog();
            }}
          />
        ),
      });

      return;
    }

    const getCertificationCandidateType = () => {
      if (!groupInfo.needVerification || (userMe.verified && !groupInfo.canViewVerifiedUserInfo)) {
        return 'pass_verification';
      }

      if (!groupInfo.canViewVerifiedUserInfo) {
        return 'join_group';
      }

      if (userMe.verified) {
        return 'join_group_with_user_info_and_verified';
      }

      return 'join_group_with_user_info_and_not_verified';
    };

    // 본인인증 결과 체크
    const verified = await handleVerificationBottomSheet({
      certificationCandidateType: getCertificationCandidateType(),
    });

    if (!verified) {
      return;
    }

    // 가입 승인이 필요 없는 경우
    if (joinApprovalRequireReason === 'none') {
      if (profileState.needToProfileSet) {
        await push('GroupProfileCreatePage', {
          groupId,
        });
        return;
      }

      await joinMutate(
        {
          id: Number(groupId),
          joinForm: {
            profile: undefined,
          },
        },
        {
          onSuccess() {
            successCallback?.();
          },
        }
      );

      return;
    }

    // 가입 범위에 해당하지 않거나, 가입 승인이 필요한 경우 (but 질문 없음)
    if (
      joinApprovalRequireReason === 'not_joinable_region_range' ||
      approvalConditions === 'none'
    ) {
      setTimeout(() => {
        openDialog({
          element: (
            <Dialog
              title="가입 신청"
              description={`모임장의 승인이 필요한 모임이에요. ${groupInfo.name} 모임에 가입할까요?`}
              primaryActionLabel="가입 신청"
              secondaryActionLabel="취소"
              onSecondaryAction={async () => {
                trackEvent({
                  event: 'click_cancel',
                  params: {
                    type: 'join_group',
                  },
                });
                await closeDialog();
              }}
              onPrimaryAction={async () => {
                await closeDialog();
                if (profileState.needToProfileSet) {
                  push('GroupProfileCreatePage', {
                    groupId,
                  });
                  return;
                }
                applicationMutate({
                  id: Number(groupId),
                  groupMemberApplicationForm: {
                    answers: [],
                    profile: undefined,
                  },
                });
              }}
            />
          ),
        });
      });

      return;
    }

    // 가입 승인이 필요한 경우 (and 질문 있음)
    if (approvalConditions === 'optional_answer' || approvalConditions === 'require_answer') {
      if (profileState.needToProfileSet) {
        await push('GroupProfileCreatePage', {
          groupId,
        });
        return;
      }

      await push('GroupProfileApprovalPage', {
        groupId: groupId,
      });
      return;
    }
  };

  return {
    isJoinedGroup: userState === 'member',
    buttonText: isAppliedGroup ? '신청 취소' : buttonText,
    handleClick: isAppliedGroup ? handleJoinCancel : handleJoinGroup,
  };
};
