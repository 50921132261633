import { GroupCurrentUser } from '@community-group/api/lib/group/models';
import {
  isMember,
  Tab,
  TabList,
  TabPanel,
  TabPanelGroup,
  Tabs,
  Typography,
} from '@community-group/components';
import { useCallback, useMemo } from 'react';

import { useGetGroupDetail } from '@/api/hooks/useGetGroupDetail';
import { useGetGroupMainProfile } from '@/api/hooks/useGetGroupMainProfile';
import { useGetGroupMe } from '@/api/hooks/useGetGroupMe';
import { useGetMemberFeeds } from '@/api/hooks/useGetMemberFeeds';
import { useGetUserProfileComments } from '@/api/hooks/useGetUserProfileComments';
import { SafeAreaBottom } from '@/components/common/SafeAreaBottom';
import { MemoizingFeedItem } from '@/components/group/Detail/components/Feed/FeedItem';
import { mapGroupFeedToPostDetail } from '@/components/group/Detail/utils/mapGroupFeedToPostDetail';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

import CommentItem from '../CommentItem';
import { LoadMoreProfileComments } from './LoadMoreProfileComments';
import { LoadMoreProfileFeeds } from './LoadMoreProfileFeeds';
import * as s from './PostAndCommentsTab.css';

export const GroupProfilePostAndCommentsTab = () => {
  const { groupId, userId } = usePathParams();

  const { group: groupDetail } = useGetGroupDetail(groupId);

  const { data: profile } = useGetGroupMainProfile({
    groupId: Number(groupId),
    userId: Number(userId),
  });

  const { data: posts, hasNextPage: hasNextPostsPage } = useGetMemberFeeds({
    groupId: Number(groupId),
    userId: Number(userId),
  });

  const { data: comments, hasNextPage: hasNextCommentsPage } = useGetUserProfileComments(
    Number(groupId),
    Number(userId)
  );
  const { data: me } = useGetGroupMe(groupId);

  const shouldUseMeetupComment = useFeatureFlag('useMeetupComment');

  const getFlattenPosts = useCallback(() => {
    if (!groupDetail || !me?.currentUser) return [];
    const flattenPosts = posts?.pages.map(({ data }) => data.items).flat(1) || [];

    const mappedPosts = flattenPosts.map(mapGroupFeedToPostDetail(groupDetail, me.currentUser));
    return mappedPosts;
  }, [groupDetail, me?.currentUser, posts]);

  const getFlattenComments = useCallback(() => {
    const flattenComments = comments?.pages.map(({ data }) => data.comments).flat(1) || [];
    if (isMember(me?.currentUser.role)) {
      return flattenComments;
    }
    return [];
  }, [comments?.pages, me?.currentUser.role]);

  const feedsCount = useMemo(
    () => (profile?.postCount ?? 0) + (profile?.meetupCount ?? 0),
    [profile.meetupCount, profile.postCount]
  );

  const tabs = useMemo(
    () => [
      {
        key: 'posts',
        buttonLabel: `게시글 ${feedsCount}`,
        render: (
          <div className={s.listWrapper}>
            <ul className={s.list}>
              {!getFlattenPosts() || getFlattenPosts().length < 1 ? (
                <div className={s.emptyBox}>작성한 게시글이 없어요.</div>
              ) : (
                getFlattenPosts().map((feed) => (
                  <MemoizingFeedItem
                    key={feed.id}
                    post={feed}
                    meetup={feed.meetupInfo}
                    currentUser={me?.currentUser as GroupCurrentUser}
                    shouldSetSubNickname={feed.isSettingOnSubNickname}
                    shouldUseMeetupComment={shouldUseMeetupComment}
                    onClickProfile={() => {}}
                  />
                ))
              )}
            </ul>
            {hasNextPostsPage && <LoadMoreProfileFeeds />}
            <SafeAreaBottom />
          </div>
        ),
      },
      {
        key: 'comments',
        buttonLabel: `댓글 ${profile?.commentCount}`,
        render: (
          <div className={s.commentListWrapper}>
            <ul className={s.list}>
              {!getFlattenComments() || getFlattenComments()?.length < 1 ? (
                <div className={s.emptyBox}>작성한 댓글이 없어요.</div>
              ) : (
                getFlattenComments().map((comment) => (
                  <CommentItem key={comment.id} item={comment} group={groupDetail} />
                ))
              )}
            </ul>
            {hasNextCommentsPage && <LoadMoreProfileComments />}
            <SafeAreaBottom />
          </div>
        ),
      },
    ],
    [
      feedsCount,
      getFlattenComments,
      getFlattenPosts,
      groupDetail,
      hasNextCommentsPage,
      hasNextPostsPage,
      me?.currentUser,
      profile?.commentCount,
      shouldUseMeetupComment,
    ]
  );

  if (!isMember(me?.currentUser.role)) {
    if (getFlattenPosts().length < 1) return <></>;

    return (
      <div>
        <Typography
          typography="title3Bold"
          style={{
            padding: '1rem',
          }}
        >
          게시글
        </Typography>
        {getFlattenPosts().map((feed) => (
          <MemoizingFeedItem
            key={feed.id}
            post={feed}
            meetup={feed.meetupInfo}
            currentUser={me?.currentUser as GroupCurrentUser}
            shouldSetSubNickname={feed.isSettingOnSubNickname}
            shouldUseMeetupComment={shouldUseMeetupComment}
            onClickProfile={() => {}}
          />
        ))}
        {hasNextPostsPage && <LoadMoreProfileFeeds />}
        <SafeAreaBottom />
      </div>
    );
  }

  return (
    <Tabs
      UNSAFE_className={s.tabWrapper}
      layout="fill"
      defaultValue={tabs[0].buttonLabel}
      isSwipeable={false}
      isLazy={true}
      lazyMode="unmount"
    >
      <TabList>
        {tabs.map((tab) => (
          <Tab key={tab.key} value={tab.buttonLabel}>
            {tab.buttonLabel}
          </Tab>
        ))}
      </TabList>
      <TabPanelGroup>
        {tabs.map((tab) => (
          <TabPanel key={tab.key} value={tab.buttonLabel}>
            {tab.render}
          </TabPanel>
        ))}
      </TabPanelGroup>
    </Tabs>
  );
};
