import { BoxButton, Spacing, Typography } from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';
import lottieLight from 'lottie-web/build/player/lottie_light.min.js';
import { useLayoutEffect } from 'react';

import LottieDoneCheck from '@/assets/images/lottie_done_check.json';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useStorage } from '@/hooks/useStorage';
import { useFlow } from '@/stackflow';
import BottomSheet from '@/stackflow/components/BottomSheet';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

import * as s from './index.css';

//TODO: 10월 하기스 매직팬티 프로모션 가입 본 마케팅 프로모션 바텀시트, 종료 후 제거 가능
const DoneCreateGroupHuggiesMarketingPromotionBottomSheet: ActivityComponentType = () => {
  const { groupId } = useQueryParams();

  useEnterTrackEvent({
    event: 'enter_create_group_marketing_huggies_bottom_sheet',
    params: {
      groupId,
    },
  });

  const [_, setMarketingPromotionBottomSheet] = useStorage(
    'promotion_group_2410_huggies_create',
    false
  );
  const { pop } = useFlow();

  const selector = `lottie-done-check`;

  useLayoutEffect(() => {
    const container = document.querySelector(`#${selector}`) as HTMLElement;
    container.innerHTML = '';
    const lottieDoneCheckData = lottieLight.loadAnimation({
      container,
      renderer: 'svg',
      autoplay: false,
      loop: false,
      animationData: LottieDoneCheck,
    });
    lottieDoneCheckData.pause();
    lottieDoneCheckData.setSpeed(1);
    setTimeout(() => {
      lottieDoneCheckData.play();
    }, 300);
  }, [selector]);

  const renderText = {
    title: '이벤트 참여 완료!',
    description: (
      <>
        {'육아 친구 만들기 대작전'} 이벤트에 응모했어요. <br />
        당첨자에게는 10월 15일에 <br />
        개별 채팅을 통해 안내드릴게요
      </>
    ),
  };

  return (
    <BottomSheet
      onOutsideClick={() => {
        setMarketingPromotionBottomSheet(true);
        pop();
      }}
      style={{
        padding: '1.5rem 1rem .5rem 1rem',
      }}
    >
      <div className={s.BottomSheetWrapper}>
        <div id={selector} className={s.CheckIconImage} />
        <Spacing height={16} />
        <Typography typography="title2Bold">{renderText.title}</Typography>
        <Spacing height={6} />
        <Typography
          typography="bodyM1Regular"
          textAlign="center"
          style={{
            whiteSpace: 'pre-wrap !important',
          }}
          color="gray700"
        >
          {renderText.description}
        </Typography>
        <Spacing height={28} />
        <BoxButton
          variant="primary"
          width="100%"
          size="xlarge"
          onClick={() => {
            setMarketingPromotionBottomSheet(true);
            pop();
          }}
        >
          확인
        </BoxButton>
      </div>
    </BottomSheet>
  );
};

export default DoneCreateGroupHuggiesMarketingPromotionBottomSheet;
