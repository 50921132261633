import { Typography } from '@community-group/components';
import { IconChevronRightFill } from '@seed-design/icon';
import { MouseEventHandler, ReactNode } from 'react';

import { Spacing, VerticalSpacing } from '@/components/common/Spacing';

import * as s from './GroupDetailMainBannerCore.css';

type Props = {
  size: 'small' | 'large';
  title: string;
  description: string;
  categoryIcon: ReactNode;
  onClick: MouseEventHandler;
};

const GroupDetailMainBanner = ({ size, title, description, categoryIcon, onClick }: Props) => {
  const isLarge = size === 'large';
  const titleTypography = isLarge ? 'subtitle2Bold' : 'caption1Bold';
  const descriptionTypography = isLarge ? 'caption1Regular' : 'caption2Regular';
  const spacingSize = isLarge ? 16 : 12;

  return (
    <div className={s.Wrapper({ size })} onClick={onClick}>
      <div className={s.Body}>
        {categoryIcon}
        <VerticalSpacing height={spacingSize} />
        <div className={s.BodyContent}>
          <Typography typography={descriptionTypography} color="gray900">
            {description}
          </Typography>
          <Spacing height={2} />
          <Typography typography={titleTypography} color="gray900">
            {title}
          </Typography>
        </div>
        <VerticalSpacing height={spacingSize} />
        <IconChevronRightFill size={16} color="gray600" />
      </div>
    </div>
  );
};

export default GroupDetailMainBanner;
