import { lazy } from 'react';

import SimpleAlertDialog from '@/components/common/SimpleAlertDialog';
import AccessStatusServiceHandlingPage from '@/components/group/AccessStatus/pages/AccessStatusServiceHandling';
import CommentDetailPage from '@/components/group/CommentDetail/pages';
import CommentEditPage from '@/components/group/CommentEdit/pages';
import CommentEmotionListPage from '@/components/group/CommentEmotionList/pages';
import CommentListPage from '@/components/group/CommentList/pages';
import GroupCurationWithKeywordPage from '@/components/group/Curation/Page/GroupCurationWithKeyword';
import JustStartedGroupMorePage from '@/components/group/Curation/Page/JustStartedGroup';
import RecommendMeetupMorePage from '@/components/group/Curation/Page/RecommendMeetup';
import ApplicationRejectedCommentBottomSheet from '@/components/group/Detail/components/ApplicationRejectedCommentBottomSheet';
import CongratsManagerRoleBottomSheet from '@/components/group/Detail/components/CongratsManagerRoleBottomSheet';
import NoticePublishGroupBottomSheet from '@/components/group/Detail/components/NoticePublishGroupBottomSheet';
import { RestrictGroupBottomSheet } from '@/components/group/Detail/components/RestrictGroupBottomSheet';
import GroupChatRoomPrivateBottomSheet from '@/components/group/Detail/components/RoomList/components/GroupChatRoomPrivateBottomSheet';
import RunningGrowthMissionCompleteBottomSheet from '@/components/group/Detail/components/RunningGrowth/RunningGrowthMissionCompleteBottomSheet';
import TurnOnNotificationBottomSheet from '@/components/group/Detail/components/TurnOnNotificationBottomSheet';
import GroupDetailNoticeMorePage from '@/components/group/Detail/pages/GroupDetailNoticeMore';
import GroupDetailNotMemberMeetupReviewListPage from '@/components/group/DetailNotMember/pages/GroupDetailNotMemberMeetupReviewListPage';
import GroupDetailNotMemberPage from '@/components/group/DetailNotMember/pages/GroupDetailNotMemberPage';
import GroupDetailPageV2 from '@/components/group/DetailV2/pages';
import GroupDetailAlbumPage from '@/components/group/DetailV2/pages/GroupDetailAlbumPage';
import GroupDetailChatRoomPage from '@/components/group/DetailV2/pages/GroupDetailChatRoomPage';
import GroupDetailFeedListPage from '@/components/group/DetailV2/pages/GroupDetailFeedListPage';
import GroupDetailMeetupListPage from '@/components/group/DetailV2/pages/GroupDetailMeetupListPage';
import GroupDetailMeetupPage from '@/components/group/DetailV2/pages/GroupDetailMeetupPage';
import GroupDetailPreviewPage from '@/components/group/DetailV2/pages/GroupDetailPreviewPage';
import GroupDetailSummaryPage from '@/components/group/DetailV2/pages/GroupDetailSummaryPage';
import LegacyGroupDetailPreviewPage from '@/components/group/DetailV2/pages/LegacyGroupDetailPreviewPage';
import { GroupEntryPointGuideBottomSheet } from '@/components/group/Home/components/BottomSheet/GroupEntryPointGuideBottomSheet';
import GroupMeetupCreateBottomSheet from '@/components/group/Home/components/BottomSheet/GroupMeetupCreateBottomSheet';
import HomePage from '@/components/group/Home/pages';
import GroupMeetupCalendarPage from '@/components/group/Home/pages/GroupMeetupCalendarPage';
import ImageViewerPage from '@/components/group/ImageViewerPage';
import DoneCreateGroupHuggiesMarketingPromotionBottomSheet from '@/components/group/MarketingPromotion/DoneCreateGroupHuggiesMarketingPromotionBottomSheet';
import DoneJoinGroupHuggiesMarketingPromotionBottomSheet from '@/components/group/MarketingPromotion/DoneJoinGroupHuggiesMarketingPromotionBottomSheet';
import GroupMeetupDetailPage from '@/components/group/Meetup/Detail/pages';
import GroupMeetupDetailAttendancePage from '@/components/group/Meetup/Detail/pages/Attendance';
import GroupMeetupDetailLikeListPage from '@/components/group/Meetup/Detail/pages/MeetupDetailLikeListPage';
import GroupMeetupDetailMemberListPage from '@/components/group/Meetup/Detail/pages/MemberList';
import TimePickerBottomSheet from '@/components/group/Meetup/Form/components/Field/TimePickerBottomSheet';
import GroupMeetupEditPage from '@/components/group/Meetup/Form/pages/Edit';
import GroupMeetupEditOptionsPage from '@/components/group/Meetup/Form/pages/Edit/Options';
import GroupMeetupNewPage from '@/components/group/Meetup/Form/pages/New';
import GroupMeetupNewOptionsPage from '@/components/group/Meetup/Form/pages/New/Options';
import GroupMeetupCreateReviewPage from '@/components/group/Meetup/Review/pages/Create';
import GroupMeetupCreateDoneReviewPage from '@/components/group/Meetup/Review/pages/GroupMeetupCreateDoneReviewPage';
import GroupMemberListPage from '@/components/group/memberList/pages';
import MemberRejectMenuBottomSheet from '@/components/group/memberList/pages/BottomSheet/MemberRejectMenuBottomSheet';
import GroupMemberListForHostPage from '@/components/group/memberList/pages/MemberListForHost';
import GroupMemberRejectMessagePage from '@/components/group/memberList/pages/MemberRejectMessage';
import MyInfoLikedGroupMeetupListPage from '@/components/group/MyInfo/LikedMeetupList';
import GroupNewSetApprovalPage from '@/components/group/new/Approval';
import GroupNewSetGroupCategoryPage from '@/components/group/new/Category';
import GroupNewSetGroupNamePage from '@/components/group/new/Name';
import { GuardForbiddenWordGroupBottomSheet } from '@/components/group/new/Name/bottomSheet/GuardForbiddenWordGroupBottomSheet';
import GroupNewSetProfileImagePage from '@/components/group/new/ProfileImage';
import GroupNewSetRecruitmentConditionPage from '@/components/group/new/RecruitmentCondition/pages';
import GroupNewSetGroupRegionRangePage from '@/components/group/new/RegionRange';
import GroupNewSetSubNicknamePage from '@/components/group/new/SubNickname';
import NewGroupSubnicknameQuestionBottomSheet from '@/components/group/new/SubNickname/components/NewGroupSubnicknameQuestion';
import { CheckCanViewVerifiedUserInfoBottomSheet } from '@/components/group/new/Verification/bottomSheets/CheckCanViewVerifiedUserInfoBottomSheet';
import { VerificationInduceBottomSheet } from '@/components/group/new/Verification/bottomSheets/VerificationInduceBottomSheet';
import GroupNewSetVerificationPage from '@/components/group/new/Verification/pages';
import GroupNotificationSelectPage from '@/components/group/Notification/pages';
import GroupOnBoardDescriptionPage from '@/components/group/OnBoard/OnBoardDescription';
import GroupOnboardProfilePage from '@/components/group/OnBoard/Profile';
import { GroupPayTransferDirectChatPage } from '@/components/group/Pay/GroupPayTransferDirectChat';
import PhotoContestPage from '@/components/group/PhotoContest/pages';
import PhotoContestGuidePage from '@/components/group/PhotoContest/pages/PhotoContestGuidePage';
import RunningPhotoGuideBottomSheet from '@/components/group/PhotoContest/PhotoContestV3/pages/RunningPhotoGuideBottomSheet';
import GroupPollFormPage from '@/components/group/Poll/pages';
import GroupPollVotedMembersPage from '@/components/group/Poll/pages/GroupPollVotedMembersPage';
import GroupPostDetailPage from '@/components/group/PostDetail/pages';
import GroupMoveBoardCategoryBottomSheet from '@/components/group/PostDetail/pages/bottomSheets/GroupMovePostBoardCategory';
import PostDetailLikeListPage from '@/components/group/PostDetail/pages/PostDetailLikeListPage';
import GroupPostEditPage from '@/components/group/PostForm/page/GroupPostEditPage';
import GroupPostNewPage from '@/components/group/PostForm/page/GroupPostNewPage';
import GroupProfileApprovalPage from '@/components/group/profile/Approval';
import GroupProfileCreatePage from '@/components/group/profile/GroupProfile/Create/pages';
import { SnsTagSetBottomSheet } from '@/components/group/profile/GroupProfile/Detail/bottomSheets/SnsTagSetBottomSheet';
import GroupProfileDetailPage from '@/components/group/profile/GroupProfile/Detail/pages';
import GroupProfileEditPage from '@/components/group/profile/GroupProfile/Edit/pages';
import GroupProfileRequestGroupDirectChatNewPage from '@/components/group/profile/GroupProfile/User/RequestGroupDirectChat/pages/new';
import MainProfileMyInfoRequestDirectChatsPage from '@/components/group/profile/MainProfile/MyInfo/RequestDirectChats/pages';
import CommentDeleteModal from '@/components/group/profile/MemberProfileDetail/components/PostAndCommentsTab/CommentDeleteModal';
import CommentMoreMenuBottomSheet from '@/components/group/profile/MemberProfileDetail/components/PostAndCommentsTab/CommentMoreMenuBottomSheet';
import GroupUserProfileDetailPage from '@/components/group/profile/MemberProfileDetail/pages';
import { GroupSetMemberProfileSubNicknameBottomSheet } from '@/components/group/profile/MemberProfileDetail/pages/bottomSheet/GroupSetMemberProfileSubNicknameBottomSheet';
import GroupEditMemberSubNicknamePage from '@/components/group/profile/MemberProfileDetail/pages/GroupEditMySubNicknamePage';
import ProfileMeetupMemberReviewMessagesPage from '@/components/group/profile/MemberProfileDetail/pages/ProfileMeetupMemberReviewMessagesPage';
import SearchHomePage from '@/components/group/Search/Page/Home';
import SearchResultPage from '@/components/group/Search/Page/Result';
import GroupSettingCreateBoardCategoryBottomSheet from '@/components/group/Setting/components/CreateBoardCategoryBottomSheet';
import GroupSettingPage from '@/components/group/Setting/page';
import BoardCategorySetting from '@/components/group/Setting/page/BoardCategorySetting';
import GroupSettingApplicationApprovalBottomSheet from '@/components/group/Setting/page/BottomSheet/ApplicationApproval';
import GroupSettingApplicationQuestionBottomSheet from '@/components/group/Setting/page/BottomSheet/ApplicationQuestion';
import GroupSettingEditGroupAgeRangeBottomSheet from '@/components/group/Setting/page/BottomSheet/EditGroupAgeRange';
import GroupSettingEditBoardCategoryNameBottomSheet from '@/components/group/Setting/page/BottomSheet/EditGroupBoardCategoryName';
import EditGroupSubnicknameQuestionBottomSheet from '@/components/group/Setting/page/BottomSheet/EditGroupSubnicknameQuestion';
import GroupSettingEditMaximumMemberBottomSheet from '@/components/group/Setting/page/BottomSheet/EditMaximumMember';
import GroupSettingEditPermissionBottomSheet from '@/components/group/Setting/page/BottomSheet/EditPermission';
import SelectGroupBeforeVerificationChange from '@/components/group/Setting/page/BottomSheet/SelectGroupBeforeVerificationChange';
import GroupSettingEditGroupDescription from '@/components/group/Setting/page/GroupSettingEditGroupDescription';
import GroupSettingEditGroupInfoPage from '@/components/group/Setting/page/GroupSettingEditGroupInfoPage';
import GroupSettingEditSubNicknamePage from '@/components/group/Setting/page/GroupSettingEditSubNicknamePage';
import GroupSettingEditVerificationPage from '@/components/group/Setting/page/GroupSettingEditVerification';
import GroupSettingFAQVerificationPage from '@/components/group/Setting/page/GroupSettingFAQVerificationPage';
import GroupSettingHeroImageSelectorPage from '@/components/group/Setting/page/GroupSettingHeroImageSelectorPage';
import GroupSettingNotificationPage from '@/components/group/Setting/page/GroupSettingNotification';
import GroupSettingPermissionPage from '@/components/group/Setting/page/GroupSettingPermissionPage';
import { GroupSettingJoinableRegionRangePage } from '@/components/group/Setting/page/JoinableRegionRange';
import GroupSettingMemberDelegateSuperHostPage from '@/components/group/Setting/page/MemberDelegateSuperHost';
import SharePostBottomSheet from '@/components/group/SharePost';
import TalkRoomInviteQRPage from '@/components/group/TalkRoom/InviteMember/pages/InviteQR';
import TalkRoomNewApprovalPage from '@/components/group/TalkRoom/New/approval';
import TalkRoomNewInformationPage from '@/components/group/TalkRoom/New/Information';
import TokenSettingPage from '@/components/group/Token/TokenSettingPage';
import VideoPOCPage from '@/components/group/VideoPOC/pages/VideoPOCPage';
import ViewVideoPage from '@/components/group/VideoPOC/pages/ViewVideoPage';

import NotFoundPage from '../pages/NotFound';

export const activityPages = {
  TokenSettingPage,
  MaintenancePage: lazy(() => import('../pages/Maintenance')),
  NotFoundPage,
  HomePage,
  JustStartedGroupMorePage,
  RecommendMeetupMorePage,
  SearchHomePage,
  SearchResultPage,
  GroupDetailPage: GroupDetailPageV2,
  GroupDetailPageLegacy: GroupDetailPageV2,
  GroupDetailAlbumPage,
  GroupDetailSummaryPage,
  GroupDetailChatRoomPage,
  GroupDetailPreviewPage,
  LegacyGroupDetailPreviewPage,
  GroupDetailMeetupPage,
  GroupProfileApprovalPage,
  GroupProfileCreatePage,
  GroupSettingPage,
  GroupSettingMemberDelegateSuperHostPage,
  GroupSettingJoinableRegionRangePage,
  GroupSettingPermissionPage,
  GroupSettingEditSubNicknamePage,
  // 본인인증 전환 관련 바텀시트
  GroupSettingEditVerification: GroupSettingEditVerificationPage,
  GroupNewSetGroupCategoryPage,
  GroupNewSetGroupNamePage,
  GroupNewSetApprovalPage,
  GroupNewSetRecruitmentConditionPage,
  GroupNewSetGroupRegionRangePage,
  GroupNewSetSubNicknamePage,
  GroupNewSetVerificationPage,
  GroupOnboardProfilePage,
  GroupMemberListPage,
  GroupMemberListForHostPage,
  GroupMemberRejectMessagePage,
  GroupPostDetailPage,
  GroupPostNewPage,
  GroupPostEditPage,
  GroupProfileDetailPage,
  GroupUserProfileDetailPage,
  GroupDetailFeedListPage,
  GroupDetailMeetupListPage,

  MainProfileMyInfoRequestDirectChatsPage,
  GroupProfileRequestGroupDirectChatNewPage,
  GroupPayTransferDirectChatPage,

  GroupProfileEditPage,
  GroupEditMemberSubNicknamePage,
  // GroupCreateProfileAndJoinGroupPage,
  PostDetailLikeListPage,

  CommentDetailPage,
  CommentEditPage,
  CommentListPage,
  CommentEmotionListPage,

  TalkRoomInviteMemberPage: lazy(() => import('@/components/group/TalkRoom/InviteMember/pages')),
  TalkRoomInviteQRPage,
  TalkRoomNewInformationPage,
  TalkRoomNewApprovalPage,
  BoardCategorySetting,
  GroupMeetupNewPage,
  GroupMeetupNewOptionsPage,
  GroupMeetupEditPage,
  GroupMeetupEditOptionsPage,
  GroupMeetupDetailPage,
  GroupMeetupDetailLikeListPage,
  ProfileMeetupMemberReviewMessagesPage,
  GroupMeetupDetailMemberListPage,
  GroupMeetupDetailAttendancePage,
  GroupMeetupDetailQRCheckPage: lazy(
    () => import('@/components/group/Meetup/Detail/pages/QRCheck')
  ),
  GroupDetailNoticeMorePage,
  MyInfoLikedGroupMeetupListPage,
  GroupPollFormPage,
  GroupPollVotedMembersPage,
  GroupOnBoardDescriptionPage,
  GroupNewSetProfileImagePage,

  GroupMeetupCalendarPage,
  GroupNotificationSelectPage,
  GroupSettingNotificationPage,
  GroupSettingEditGroupDescription,

  GroupMeetupCreateReviewPage,
  GroupMeetupCreateDoneReviewPage,
  GroupSettingEditGroupInfoPage,
  GroupCurationWithKeywordPage,

  GroupSettingFAQVerificationPage,
  AccessStatusServiceHandlingPage,
  ImageViewerPage,
  GroupSettingHeroImageSelectorPage,
  SimpleAlertDialog,

  PhotoContestPage,
  PhotoContestGuidePage,
  'BottomSheet/RunningPhotoGuideBottomSheet': RunningPhotoGuideBottomSheet, // 3회차 사진전 러닝그로스 바텀시트

  GroupDetailNotMemberPage, // 디버깅용 route - 모임 상세 미가입자뷰
  GroupDetailNotMemberMeetupReviewListPage,

  VideoPOCPage, // 비디오 업로드 POC 페이지
  ViewVideoPage, // 비디오 재생 페이지

  'BottomSheet/TurnOnNotificationBottomSheet': TurnOnNotificationBottomSheet,
  'BottomSheet/GroupSettingApplicationApprovalBottomSheet':
    GroupSettingApplicationApprovalBottomSheet,
  'BottomSheet/GroupSettingApplicationQuestionBottomSheet':
    GroupSettingApplicationQuestionBottomSheet,
  'BottomSheet/GroupSettingEditMaximumMemberBottomSheet': GroupSettingEditMaximumMemberBottomSheet,
  'BottomSheet/GroupSettingEditGroupAgeRangeBottomSheet': GroupSettingEditGroupAgeRangeBottomSheet,
  'BottomSheet/GroupSettingCreateBoardCategoryBottomSheet':
    GroupSettingCreateBoardCategoryBottomSheet,
  'BottomSheet/GroupSettingEditPermissionBottomSheet': GroupSettingEditPermissionBottomSheet,
  'BottomSheet/GroupSettingEditSubNicknameQuestionBottomSheet':
    EditGroupSubnicknameQuestionBottomSheet,
  'BottomSheet/NoticePublishGroupBottomSheet': NoticePublishGroupBottomSheet,
  'BottomSheet/GroupMeetupCreateBottomSheet': GroupMeetupCreateBottomSheet,
  'BottomSheet/GroupEntryPointGuideBottomSheet': GroupEntryPointGuideBottomSheet,
  'BottomSheet/RestrictGroupBottomSheet': RestrictGroupBottomSheet,
  'BottomSheet/ApplicationRejectedCommentBottomSheet': ApplicationRejectedCommentBottomSheet,

  'BottomSheet/GroupSettingEditBoardCategoryNameBottomSheet':
    GroupSettingEditBoardCategoryNameBottomSheet,
  'BottomSheet/GroupSetMemberProfileSubNicknameBottomSheet':
    GroupSetMemberProfileSubNicknameBottomSheet,
  'BottomSheet/GroupMoveBoardCategoryBottomSheet': GroupMoveBoardCategoryBottomSheet,
  'BottomSheet/GroupChatRoomPrivateBottomSheet': GroupChatRoomPrivateBottomSheet,
  'BottomSheet/CongratsManagerRoleBottomSheet': CongratsManagerRoleBottomSheet,
  'BottomSheet/VerificationInduceBottomSheet': VerificationInduceBottomSheet,
  'BottomSheet/TimePickerBottomSheet': TimePickerBottomSheet,
  'BottomSheet/GroupSetNewSubnicknameQuestion': NewGroupSubnicknameQuestionBottomSheet,
  'BottomSheet/CheckCanViewVerifiedUserInfoBottomSheet': CheckCanViewVerifiedUserInfoBottomSheet,
  'BottomSheet/MemberRejectMenuBottomSheet': MemberRejectMenuBottomSheet,

  'BottomSheet/SelectGroupBeforeVerificationChange': SelectGroupBeforeVerificationChange,

  // 러닝그로스 완료 바텀시트
  'BottomSheet/RunningGrowthMissionCompleteBottomSheet': RunningGrowthMissionCompleteBottomSheet,

  //TODO: 10월 육아 모임 가입 사전신청 마케팅 프로모션 바텀시트, 종료 후 제거 가능
  'BottomSheet/DoneJoinGroupHuggiesMarketingPromotionBottomSheet':
    DoneJoinGroupHuggiesMarketingPromotionBottomSheet,
  'BottomSheet/DoneCreateGroupHuggiesMarketingPromotionBottomSheet':
    DoneCreateGroupHuggiesMarketingPromotionBottomSheet,

  'BottomSheet/GuardForbiddenWordGroupBottomSheet': GuardForbiddenWordGroupBottomSheet,
  'BottomSheet/SnsTagSetBottomSheet': SnsTagSetBottomSheet,

  'BottomSheet/SharePostBottomSheet': SharePostBottomSheet,
  'BottomSheet/CommentMoreMenuBottomSheet': CommentMoreMenuBottomSheet,
  'Modal/CommentDeleteModal': CommentDeleteModal,
};
