import { Spacing } from '@community-group/components';
import React, { useCallback, useEffect, useState } from 'react';

import { useGetComments } from '@/api/hooks/useGetComments';
import { useGetPostDetail } from '@/api/hooks/useGetPostDetail';
import EmptySection from '@/components/common/Empty';
import { LoadMoreListContainer } from '@/components/common/LoadMoreContainer';
import CommentFeed from '@/components/group/CommentList/components/CommentFeed';
import { usePathParams } from '@/stackflow/hooks/usePathParams';

const CommentList = () => {
  return <CommentListWrapper />;
};

const CommentListWrapper = () => {
  const { groupId, postId, commentId } = usePathParams();

  const { post, isLoading } = useGetPostDetail(groupId, postId);

  const { data, hasNextPage, fetchNextPage } = useGetComments({
    groupId,
    relatedId: postId,
    relatedContentType: 'post',
  });

  // 컨텐츠보다 목록이 먼저뜨는 것을 방지하기 위함.
  const [shownCommentList, setShownCommentList] = useState(false);
  useEffect(() => {
    if (isLoading) return;

    setTimeout(() => {
      setShownCommentList(true);
    }, 500);
  }, [isLoading]);

  const Comment = useCallback(() => {
    if (data && data.pages[0].data.comments.length < 1) {
      if (post.author.isAccountDeleted) {
        return <EmptySection>작성된 댓글이 없어요.</EmptySection>;
      }

      return (
        <EmptySection>
          아직 댓글이 없어요.
          <br />
          가장 먼저 댓글을 남겨보세요.
        </EmptySection>
      );
    }

    return (
      <CommentFeed
        groupId={groupId}
        relatedId={postId}
        commentId={commentId}
        relatedContentType="post"
        comments={data?.pages.flatMap((page) => page.data.comments) ?? []}
        disabledCommentInput={post.author.isAccountDeleted ?? false}
      >
        {hasNextPage && <LoadMoreListContainer callback={fetchNextPage} />}
        {post.author.isAccountDeleted && <Spacing height={40} />}
      </CommentFeed>
    );
  }, [data]);

  if (!post || !shownCommentList) return <></>;

  return (
    <>
      {/* TODO: 사진전 할 때 오픈*/}
      {/* {post?.isPhotoContestEntry && (
        <div className={s.BannerWrapper}>
          <ActionableCallout UNSAFE_style={{ width: '100%' }} onClick={handlePhotoContestClick}>
            <CalloutTitle>전국모임자랑 이벤트📸</CalloutTitle>
            <CalloutDescription>
              다른 모임들은 어떤 사진을 자랑했는지 지금 확인해보세요!
            </CalloutDescription>
          </ActionableCallout>
        </div>
      )} */}
      <div id="post-comment-section">
        <Comment />
      </div>
    </>
  );
};

export default React.memo(CommentList);
