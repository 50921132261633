import { GroupDetailPresentation, UserComment } from '@community-group/api/lib/group/models';
import {
  ContainerWithGap,
  getDateDiffFromNow,
  GroupAvatar,
  isNotMember,
  Spacing,
  Typography,
} from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconMoreVertRegular } from '@seed-design/icon';
import { useActivity } from '@stackflow/react';

import { useDeleteComment } from '@/api/hooks/useDeleteComment';
import { useDeleteCommentEmotion } from '@/api/hooks/useDeleteCommentEmotion';
import { getCommentEmotionsPath } from '@/api/hooks/useGetCommentEmotions';
import { useGetGroupMe } from '@/api/hooks/useGetGroupMe';
import { usePatchCommentEmotion } from '@/api/hooks/usePatchCommentEmotion';
import { queryClient } from '@/api/instance';
import { CommentImageSlider } from '@/components/common/CommentFeedItem/CommentImageSlider';
import CommentReaction from '@/components/common/CommentFeedItem/CommentReaction';
import LinkifyContainer from '@/components/common/Linkify';
import OpenGraph from '@/components/common/OpenGraph';
import { VerticalSpacing } from '@/components/common/Spacing';
import {
  BLOCK_GROUP_ONLY_TEXT,
  useHandleGroupOnly,
} from '@/components/group/JoinGroupState/hooks/useHandleGroupOnly';
import { useBridge } from '@/contexts/Bridge';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useConnectedFlow, useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';
import { isShowOpenGraphContent } from '@/utils/check';
import { openGroupCommentReport } from '@/utils/link';
import { convertNicknameDisplay } from '@/utils/nickname';
import { refetchGroupProfileComments } from '@/utils/refetch/memberProfile';

import * as s from './CommentItem.css';
import { CommentDeleteModalPopResponseType } from './PostAndCommentsTab/CommentDeleteModal';
import { CommentMoreMenuBottomSheetPopResponseType } from './PostAndCommentsTab/CommentMoreMenuBottomSheet';

type Props = {
  item: UserComment;
  group?: GroupDetailPresentation;
};

const CommentItem = ({ item, group }: Props) => {
  const {
    id,
    relatedContent,
    content,
    emotion,
    images = [],
    author,
    isSubComment,
    parentCommentId,
    subCommentCount = 0,
  } = item;
  const { push } = useFlow();

  const { push: connectedPush } = useConnectedFlow();
  const groupId = group?.id.toString() ?? '';
  const { data: me } = useGetGroupMe(groupId);
  const { name: activityName } = useActivity();

  const handleErrorWithToast = useHandleErrorWithToast();

  const authorId = author?.id?.toString() ?? '';
  const relatedContentId = relatedContent.id.toString();

  const { mutate: mutateDeleteComment } = useDeleteComment({
    relatedContentType: relatedContent.type,
    onError: (error) => handleErrorWithToast(error),
    onSuccess: () => {
      refetchGroupProfileComments({
        groupId,
        postId: relatedContentId,
        commentId: id.toString(),
        authorId,
      });
    },
  });

  const { mutateAsync: mutateLikeComment } = usePatchCommentEmotion({
    relatedContentType: relatedContent.type,
    onError: handleErrorWithToast,
    onSuccess: () => {
      trackEvent({
        event: 'click_like',
        params: {
          contentType: 'comment',
          groupId,
          from: activityName,
        },
        sample: true,
      });
      refetchGroupProfileComments({
        groupId,
        postId: relatedContentId,
        commentId: id.toString(),
        authorId,
      });
    },
  });

  const { mutateAsync: mutateDislikeComment } = useDeleteCommentEmotion({
    relatedContentType: relatedContent.type,
    onError: handleErrorWithToast,
    onSuccess: () => {
      trackEvent({
        event: 'click_like',
        params: {
          contentType: 'comment',
          groupId,
          from: activityName,
        },
        sample: true,
      });
      refetchGroupProfileComments({
        groupId,
        postId: relatedContentId,
        commentId: id.toString(),
        authorId,
      });
    },
  });

  const openCommentEmotionListPage = (targetCommentId: number) => {
    push('CommentEmotionListPage', {
      groupId,
      relatedContentType: relatedContent.type,
      relatedId: relatedContentId,
      commentId: targetCommentId.toString(),
    });

    trackEvent({
      event: 'click_like_list',
      params: {
        type: 'comment',
        contentType: 'comment',
        groupId,
        from: activityName,
      },
      sample: true,
    });
  };

  const handleMenuClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    const commentId = id.toString();
    const currentUserId = me?.currentUser.id;
    const commentAuthorId = author?.id;

    const hasEmotionCount = emotion && emotion.count > 0;
    const moveCommentEmotionListPage = async () => {
      openCommentEmotionListPage(id);
    };

    const isEqualCurrentCommentAuthor = currentUserId === commentAuthorId;
    const hasDeletePermission = me?.currentUser.permissions.deletePostAndComment;

    const hasPermission = isEqualCurrentCommentAuthor || hasDeletePermission;

    const result: CommentMoreMenuBottomSheetPopResponseType = await connectedPush(
      'BottomSheet/CommentMoreMenuBottomSheet',
      {
        isEqualCurrentCommentAuthor: isEqualCurrentCommentAuthor,
        hasEmotionCount: hasEmotionCount,
        hasPermission: hasPermission,
      }
    );

    if (result.type === 'editComment') {
      push('CommentEditPage', {
        groupId,
        relatedContentType: relatedContent.type,
        relatedId: relatedContentId,
        commentId,
      });
      return;
    }

    if (result.type === 'showEmotionList') {
      moveCommentEmotionListPage();
      return;
    }

    if (result.type === 'reportComment') {
      trackEvent({
        event: 'click_report',
        params: {
          type: 'comment',
          groupId,
        },
      });
      openGroupCommentReport(commentId, commentAuthorId.toString());
      return;
    }

    if (result.type === 'deleteComment') {
      const result: CommentDeleteModalPopResponseType = await connectedPush(
        'Modal/CommentDeleteModal',
        {}
      );

      if (result.type === 'cancel') return;

      await mutateDeleteComment({
        groupId,
        relatedId: relatedContentId,
        commentId,
      });

      return;
    }
  };

  const onContentClick = (e) => {
    e.stopPropagation();
    push('CommentDetailPage', {
      groupId: groupId,
      groupName: group?.name,
      relatedContentType: relatedContent.type,
      relatedId: relatedContentId,
      commentId: id.toString(),
      commentCount: subCommentCount.toString(),
    });
  };

  const { bridge } = useBridge();

  const { handleGroupOnly } = useHandleGroupOnly({ groupId });

  const handleCommentLikeClick = () => {
    bridge.setHapticLightFeedback({});

    const likeComment = async () => {
      await mutateLikeComment({
        groupId,
        relatedId: relatedContentId,
        commentId: id.toString(),
      });
      const queryKey = getCommentEmotionsPath(
        Number(groupId),
        Number(relatedContentId),
        id,
        relatedContent.type
      );
      queryClient.refetchQueries([queryKey]);
    };

    const blockedGroupOnly = isNotMember(me?.currentUser.role);
    handleGroupOnly({
      isBlock: !blockedGroupOnly,
      blockGroupOnlyTitle: '모임 가입 안내',
      blockGroupOnlyText: BLOCK_GROUP_ONLY_TEXT.getCommentReaction,
      onSettled: likeComment,
      onSuccess: async () => {
        if (group?.subNicknameSetting.isSettingOn) {
          push('BottomSheet/GroupSetMemberProfileSubNicknameBottomSheet', {
            groupId,
          });
        }
        await likeComment();
      },
    });
  };

  const handleCommentDislikeClick = async () => {
    bridge.setHapticLightFeedback({});

    await mutateDislikeComment({
      groupId,
      relatedId: relatedContentId,
      commentId: id.toString(),
    });
    const queryKey = getCommentEmotionsPath(
      Number(groupId),
      Number(relatedContentId),
      id,
      relatedContent.type
    );
    queryClient.refetchQueries([queryKey]);
  };

  return (
    <div className={s.FeedItem}>
      <div aria-hidden="true">
        <GroupAvatar src={item.author.profileImage || ''} role={item.author.role} size={'small'} />
      </div>
      <VerticalSpacing height={12} />
      <div className={s.FeedItemRightSection}>
        <div className={s.FeedItemRightTopSection}>
          <div className={s.FeedItemAuthorSection} aria-hidden="true">
            <Typography typography="caption1Bold" color="gray900">
              {convertNicknameDisplay({
                nickname: item.author.nickname ?? '',
                subNickname: item.author.subNickname,
              })}
            </Typography>
            <Spacing height={2} />
            <Typography typography="caption2Regular" color="gray600">
              {getDateDiffFromNow(new Date(Date.parse(item.createdAt))).text} 전{' '}
            </Typography>
          </div>
          <button onClick={handleMenuClick}>
            <IconMoreVertRegular size={24} color={vars.$scale.color.gray600} />
          </button>
        </div>
        <Spacing height={8} />
        <ContainerWithGap
          className={s.Content({ isSubComment })}
          onClick={onContentClick}
          name="CommentFeedItemContent"
          gap={8}
        >
          {item.content && <LinkifyContainer text={content} />}
          {images?.length > 0 && <CommentImageSlider images={images} />}
          {isShowOpenGraphContent(item.content) && (
            <OpenGraph text={item.content} type="FEED" margin="0 0 .5rem 0" />
          )}
        </ContainerWithGap>

        <Spacing height={8} />
        <CommentReaction
          emotionCount={emotion?.count ?? 0}
          myEmotion={emotion?.myEmotion ?? ''}
          parentId={parentCommentId}
          subCommentCount={item.subCommentCount ?? 0}
          onLikeReactionClick={handleCommentLikeClick}
          onDislikeReactionClick={handleCommentDislikeClick}
          onReplyClick={onContentClick}
        />
      </div>
    </div>
  );
};

export default CommentItem;
