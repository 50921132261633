import { GroupCurrentUser, GroupDetailPresentation } from '@community-group/api/lib/group/models';
import { IconCategoryTogether } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { useMemo } from 'react';

import { useFlow } from '@/stackflow';
import { trackEvent } from '@/utils/analytics';

import GroupDetailMainBanner from './GroupDetailMainBannerCore';
import * as s from './GroupDetailMainBannerCore.css';

type Props = {
  group: GroupDetailPresentation;
  currentUser: GroupCurrentUser;
  meetupId: string;
};

const GroupDetailMeetupReviewBanner = ({ group, currentUser, meetupId }: Props) => {
  const groupId = useMemo(() => group.id.toString(), [group.id]);

  const { push } = useFlow();
  const handleClick = () => {
    trackEvent({
      event: 'click_write_group_meetup_review_callout',
      params: {
        groupId: groupId,
        meetupId: meetupId,
        groupName: group.name,
        groupCategoryName: group.category.name,
        isGroupCategoryOn: group.isBoardManaged,
        role: currentUser.role,
        version: '2',
      },
    });
    push('GroupMeetupCreateReviewPage', {
      groupId: groupId,
      meetupId: meetupId,
    });
  };

  return (
    <GroupDetailMainBanner
      size="large"
      title="일정 후기 남기기"
      description="참여한 일정은 어떠셨나요?"
      categoryIcon={
        <div className={s.IconWrapper({ color: 'yellow50' })}>
          <IconCategoryTogether size={22} color={vars.$scale.color.yellow400} />
        </div>
      }
      onClick={handleClick}
    />
  );
};

export default GroupDetailMeetupReviewBanner;
