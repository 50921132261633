import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useStorage } from '@/hooks/useStorage';
import { useFlow } from '@/stackflow';
import { useStore } from '@/store';
import { isDevelopmentAppStage } from '@/utils/check';

import {
  isDuringRealJoinHuggiesGroupMarketingPromotion,
  isShowJoinHuggiesGroupMarketingBanner,
} from '../utils';

// TODO: 10월 운동 마케팅 본 프로모션 관련 분기용 유틸 한번 더 진행하면 모듈로 분리
function useCreateGroupHuggiesMarketingPromotion() {
  const [marketingPromotionBottomSheet] = useStorage('promotion_group_2410_huggies_create', false);
  const { push } = useFlow();

  const isOngoingHuggiesGroupJoinMarketingPromotion = useFeatureFlag(
    'huggiesGroupJoinMarketingPromotion'
  );

  const validIsOnGoingHuggiesGroupCreateMarketingPromotion = () => {
    //  개발환경일 경우 무조건 노출
    if (isDevelopmentAppStage()) return true;

    //피쳐플래그 오픈 여부 확인
    if (!isOngoingHuggiesGroupJoinMarketingPromotion) return false;

    // 이미 오픈되었는지 여부 확인
    if (marketingPromotionBottomSheet) return false;

    // 이벤트 기간인지 여부 확인
    if (!isDuringRealJoinHuggiesGroupMarketingPromotion()) return false;

    return true;
  };

  // 모임 홈 배너, 모임 상세 띠배너, 게시글 하단 배너 노출 분기
  const validIsShowHuggiesGroupCreateMarketingBanner = () => {
    //  개발환경일 경우 무조건 노출
    if (isDevelopmentAppStage()) return true;

    //피쳐플래그 오픈 여부 확인
    if (!isOngoingHuggiesGroupJoinMarketingPromotion) return false;

    // 이벤트 기간인지 여부 확인
    if (!isShowJoinHuggiesGroupMarketingBanner()) return false;

    return true;
  };

  const handleOpenCreateHuggiesMarketingPromotionBottomSheet = ({
    groupId,
  }: {
    groupId: string;
  }) => {
    const isValid = validIsOnGoingHuggiesGroupCreateMarketingPromotion();

    if (!isValid) return false;

    setTimeout(() => {
      push('BottomSheet/DoneCreateGroupHuggiesMarketingPromotionBottomSheet', {
        groupId,
      });
    }, 500);
    return true;
  };

  return {
    handleOpenCreateHuggiesMarketingPromotionBottomSheet,
    validIsOnGoingHuggiesGroupCreateMarketingPromotion,
    validIsShowHuggiesGroupCreateMarketingBanner,
  };
}

export default useCreateGroupHuggiesMarketingPromotion;
