import { GroupSidebarApplicationsPresentation } from '@community-group/api/lib/group/models';
import { MouseEventHandler } from 'react';

import SidebarApplicationBanner from './SidebarApplicationBanner';
import SidebarMemberMissionBanner from './SidebarMemberMissionBanner';

type Props = {
  groupId: number;
  applications?: GroupSidebarApplicationsPresentation;
  onApplicationBannerClick: MouseEventHandler;
  onMissionBannerClick: MouseEventHandler;
  isHost: boolean;
};

const SidebarBanner = ({
  groupId,
  applications,
  onApplicationBannerClick,
  onMissionBannerClick,
  isHost,
}: Props) => {
  if (applications) {
    return (
      <SidebarApplicationBanner
        appliedApplicationCount={applications.appliedApplicationCount}
        onClick={onApplicationBannerClick}
      />
    );
  }

  if (!isHost) {
    return <SidebarMemberMissionBanner groupId={groupId} onClick={onMissionBannerClick} />;
  }
};

export default SidebarBanner;
