import { activityPages } from './pages';

type Routes = Record<keyof typeof activityPages, string>;

const groupMeetupRoutes = {
  GroupMeetupNewPage: '/group/:groupId/meetups/new',
  GroupMeetupNewOptionsPage: '/group/:groupId/meetups/new/options',
  GroupMeetupEditPage: '/group/:groupId/meetups/:meetupId/edit',
  GroupMeetupEditOptionsPage: '/group/:groupId/meetups/:meetupId/edit/options',
  GroupMeetupDetailPage: '/group/:groupId/meetups/:meetupId',
  GroupMeetupDetailMemberListPage: '/group/:groupId/meetups/:meetupId/member-list',
  GroupMeetupDetailAttendancePage: '/group/:groupId/meetups/:meetupId/attendance',
  GroupMeetupDetailQRCheckPage: '/group/:groupId/meetups/:meetupId/qr-check/:code',
  GroupMeetupDetailLikeListPage: '/group/:groupId/meetups/:meetupId/like-list',
  GroupMeetupCalendarPage: '/group/meetups/calendar',
  GroupMeetupCreateReviewPage: '/group/:groupId/meetups/:meetupId/review/new',
  GroupMeetupCreateDoneReviewPage: '/group/meetups/review/done',

  'BottomSheet/GroupMeetupCreateBottomSheet': '/bottom-sheet/group/meetups/create',
};

const groupSettingRoutes = {
  GroupSettingPage: '/setting/:groupId',
  GroupSettingMemberDelegateSuperHostPage: '/setting/member/delegate-super-host/:groupId',
  GroupSettingJoinableRegionRangePage: '/setting/joinable-region-range/:groupId',
  GroupSettingPermissionPage: '/setting/permission/:groupId',
  GroupSettingEditSubNicknamePage: '/setting/edit-subnickname/:groupId',
  GroupSettingNotificationPage: '/group/:groupId/setting/notification',
  BoardCategorySetting: '/setting/board-category/:groupId',
  GroupSettingEditGroupDescription: '/setting/:groupId/edit-group-description',
  GroupSettingEditGroupInfoPage: '/setting/:groupId/edit-group-info',
  GroupSettingEditVerification: '/setting/:groupId/edit-verification',
  'BottomSheet/GroupSettingApplicationApprovalBottomSheet':
    '/bottom-sheet/setting/:groupId/application-approval',
  'BottomSheet/GroupSettingApplicationQuestionBottomSheet':
    '/bottom-sheet/setting/:groupId/application-question',
  'BottomSheet/GroupSettingEditMaximumMemberBottomSheet':
    '/bottom-sheet/setting/:groupId/edit-maximum-member',
  'BottomSheet/GroupSettingEditGroupAgeRangeBottomSheet':
    '/bottom-sheet/setting/:groupId/edit-group-age-range',
  'BottomSheet/GroupSettingCreateBoardCategoryBottomSheet':
    '/bottom-sheet/setting/:groupId/crate-board-category',
  'BottomSheet/GroupSettingEditBoardCategoryNameBottomSheet':
    'bottom-sheet/setting/:groupId/edit/board-category-name',
  'BottomSheet/GroupSettingEditSubNicknameQuestionBottomSheet':
    'bottom-sheet/setting/:groupId/edit/subnickname/question',
  'BottomSheet/GroupSettingEditPermissionBottomSheet':
    'bottom-sheet/setting/:groupId/edit-permission',
  'BottomSheet/NoticePublishGroupBottomSheet': '/bottom-sheet/notification/publish-group/:groupId',
  'BottomSheet/TurnOnNotificationBottomSheet': '/bottom-sheet/notification/:groupId',
};

const groupCreateRoutes = {
  GroupNewSetGroupCategoryPage: '/new/set-category',
  GroupNewSetGroupNamePage: '/new/set-name',
  GroupNewSetApprovalPage: '/new/set-approval',
  GroupNewSetRecruitmentConditionPage: '/new/set-recruitment-condition',
  GroupNewSetGroupRegionRangePage: '/new/set-group-region-range',
  GroupNewSetSubNicknamePage: '/new/subnickname',
  GroupNewSetVerificationPage: '/new/set-verification',
  GroupNewSetProfileImagePage: '/new/set-profile-image',
};

const growthEventRoutes = {
  // 러닝그로스 완료 바텀시트
  'BottomSheet/RunningGrowthMissionCompleteBottomSheet':
    '/bottom-sheet/group/:groupId/running-growth-mission-complete/:step',

  //TODO: 10월 육아 모임 사전 신청 마케팅 프로모션 바텀시트, 종료 후 제거 가능
  'BottomSheet/DoneJoinGroupHuggiesMarketingPromotionBottomSheet':
    '/bottom-sheet/done-join-group-huggies-marketing-promotion',
  //TODO: 10월 육아 모임 본 이벤트 참여 프로모션 바텀시트, 종료 후 제거 가능
  'BottomSheet/DoneCreateGroupHuggiesMarketingPromotionBottomSheet':
    '/bottom-sheet/done-create-group-huggies-marketing-promotion',
};

const ProfileBottomSheetRoutes = {
  'BottomSheet/SnsTagSetBottomSheet': '/bottom-sheet/sns-set-tag',
};

const NotMemberDetailRoutes = {
  GroupDetailNotMemberPage: '/group/detail/:groupId/not-member', // 디버깅용 Path, 실 배포시 삭제
  GroupDetailNotMemberMeetupReviewListPage: '/group/:groupId/meetup-review-list',
};

export const routes: Routes = {
  ...NotMemberDetailRoutes,
  ...groupMeetupRoutes,
  ...groupSettingRoutes,
  ...groupCreateRoutes,
  ...growthEventRoutes,
  ...ProfileBottomSheetRoutes,
  MaintenancePage: '/maintenance',
  NotFoundPage: '/not-found',
  HomePage: '/home',
  JustStartedGroupMorePage: '/more/just-started-group',
  RecommendMeetupMorePage: '/more/recommend_meetups',
  SearchHomePage: '/search/home',
  SearchResultPage: '/search/result/:query',
  GroupDetailPage: '/group/:groupId',
  // NOTE: GroupDetailPageLegacy
  // 모임 상세 V1에서 사용하던 URL로 알림, 채팅 등에서 정적으로 박혀있는 경로라 삭제하지 않아요.
  // 실제로 랜딩되는 페이지는 GroupDetailPage와 같아요.
  GroupDetailPageLegacy: '/group/detail/:groupId',
  GroupDetailAlbumPage: '/group/:groupId/album',
  GroupDetailSummaryPage: '/group/:groupId/summary',
  GroupDetailChatRoomPage: '/group/:groupId/chat-room',
  GroupDetailPreviewPage: '/group/:groupId/preview',
  LegacyGroupDetailPreviewPage: '/group/:groupId/preview-legacy',
  GroupProfileApprovalPage: '/profile/approval/:groupId',
  GroupProfileCreatePage: '/profile/create',
  GroupProfileDetailPage: '/group/profile/detail',
  GroupDetailMeetupPage: '/group/:groupId/meetups',
  GroupDetailMeetupListPage: '/group/:groupId/meetup-list',
  GroupDetailFeedListPage: '/group/:groupId/feed-list',

  MainProfileMyInfoRequestDirectChatsPage: '/main-profile/my-info/request-direct-chats',
  GroupProfileRequestGroupDirectChatNewPage:
    '/group-profile/:groupId/:userId/request-group-direct-chat/new',
  GroupPayTransferDirectChatPage: '/group-pay/transfer/direct-chat/:chatChannelId',

  ProfileMeetupMemberReviewMessagesPage: '/profile/:userId/meetup-member-review-messages',

  GroupOnboardProfilePage: '/new/onboard/set-profile/:groupId',
  GroupOnBoardDescriptionPage: '/new/set-publish/:groupId',
  GroupMemberListPage: '/member-list/:groupId',
  GroupMemberListForHostPage: '/member-list/host/:groupId',
  GroupMemberRejectMessagePage: '/member-list/host/:groupId/reject',
  GroupPostDetailPage: '/post/detail/:groupId/posts/:postId',
  GroupPostNewPage: '/group/:groupId/posts/new',
  GroupPostEditPage: '/group/:groupId/posts/:postId/edit',
  GroupUserProfileDetailPage: '/profile/detail/:groupId/:userId', // 이후 통합 프로필로 넘어가면 삭제
  GroupProfileEditPage: '/profile/edit',
  GroupEditMemberSubNicknamePage: '/group/:groupId/member/edit-subnickname',
  // GroupCreateProfileAndJoinGroupPage: '/profile/create-profile-join-group/:groupId',
  PostDetailLikeListPage: '/like-list/:groupId/:postId',

  CommentDetailPage: '/group/:groupId/:relatedContentType/:relatedId/comment/:commentId',
  CommentEditPage: '/group/:groupId/:relatedContentType/:relatedId/comment/:commentId/edit',
  CommentListPage: '/group/:groupId/:relatedContentType/:relatedId/comment-list',
  CommentEmotionListPage:
    '/group/:groupId/:relatedContentType/:relatedId/comment/:commentId/emotion-list',

  TalkRoomInviteMemberPage: '/talk/:groupId/room/:roomId/invite-member',
  TalkRoomInviteQRPage: '/talk/:groupId/room/:roomId/invite-qr',
  TalkRoomNewInformationPage: '/talk/:groupId/room/new/information',
  TalkRoomNewApprovalPage: '/talk/:groupId/room/new/approval',

  MyInfoLikedGroupMeetupListPage: '/my-info/group/meetups/liked-list',
  GroupDetailNoticeMorePage: '/group/:groupId/notice',
  GroupPollFormPage: '/group/:groupId/poll/form',
  GroupPollVotedMembersPage: '/group/:groupId/posts/:postId/poll/voted-members',

  GroupCurationWithKeywordPage: '/group/curation/with-keyword',

  GroupNotificationSelectPage: '/group/notification/select',
  TokenSettingPage: '/token-setting',
  GroupSettingFAQVerificationPage: '/group/setting/faq-verification',
  GroupSettingHeroImageSelectorPage: '/group/:groupId/setting/hero-image-selector',

  AccessStatusServiceHandlingPage: '/access-status-service-handling',
  ImageViewerPage: '/image-viewer',

  SimpleAlertDialog: '/alert',

  PhotoContestPage: '/photo-contest',
  PhotoContestGuidePage: '/photo-contest/guide',

  VideoPOCPage: '/video-poc',
  ViewVideoPage: '/video-poc/view',

  'BottomSheet/RunningPhotoGuideBottomSheet': '/bottom-sheet/photo-contest/running-photo-guide',

  'BottomSheet/GroupEntryPointGuideBottomSheet': '/bottom-sheet/group/entry-point-guide',
  'BottomSheet/RestrictGroupBottomSheet': '/bottom-sheet/admin/:groupId/restrict-group',

  'BottomSheet/GroupSetMemberProfileSubNicknameBottomSheet':
    '/bottom-sheet/:groupId/set-member-profile-subnickname',
  'BottomSheet/GroupMoveBoardCategoryBottomSheet':
    '/group/:groupId/posts/:postId/move/board-category',
  'BottomSheet/GroupChatRoomPrivateBottomSheet':
    '/bottom-sheet/group/:groupId/chat-room/private/:roomId',
  'BottomSheet/CongratsManagerRoleBottomSheet': '/bottom-sheet/congrats-manager-role',
  'BottomSheet/VerificationInduceBottomSheet': '/bottom-sheet/induce-verification',
  'BottomSheet/TimePickerBottomSheet': '/bottom-sheet/time-picker',
  'BottomSheet/GroupSetNewSubnicknameQuestion': 'bottom-sheet/new/subnickname/question',
  'BottomSheet/CheckCanViewVerifiedUserInfoBottomSheet':
    '/bottom-sheet/check-can-view-verified-user-info',
  'BottomSheet/MemberRejectMenuBottomSheet': '/bottom-sheet/member/reject-menu/:groupId/:userId',
  'BottomSheet/ApplicationRejectedCommentBottomSheet':
    '/bottom-sheet/application/rejected/:groupId/:userId',
  'BottomSheet/SelectGroupBeforeVerificationChange': '/bottom-sheet/select-group-verification',
  // 러닝그로스 완료 바텀시트
  'BottomSheet/RunningGrowthMissionCompleteBottomSheet':
    '/bottom-sheet/group/:groupId/running-growth-mission-complete/:step',
  'BottomSheet/GuardForbiddenWordGroupBottomSheet': '/bottom-sheet/guard-forbidden-word-group',

  'BottomSheet/SharePostBottomSheet': '/bottom-sheet/share/content',
  'BottomSheet/CommentMoreMenuBottomSheet': '/bottom-sheet/comment/more-menu',
  'Modal/CommentDeleteModal': '/modal/comment/delete',
};
