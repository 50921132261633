// 운동 마케팅 프로모션 기간인지 확인하는 함수
export const isDuringMarketingPromotion = () => {
  // 한국시 4월 14일 24시까지 마케팅 프로모션 기간
  const endPromotionUtcDate = new Date(Date.UTC(2024, 3, 14, 15, 0)); // 월은 0부터 시작하므로 4월이면 3으로 설정
  const now = new Date();
  return now < endPromotionUtcDate;
};

// 10월 하기스 가입 마케팅 프로모션 기간인지 확인하는 함수
// TODO: 일정 수정해야함
export const isDuringRealJoinHuggiesGroupMarketingPromotion = () => {
  // 한국시 9월 26일 00시 부터 9월 15일 24시까지 마케팅 프로모션 기간
  if (globalThis.appConfig.appEnv === 'alpha') {
    return true;
  }
  const startPromotionUtcDate = new Date(Date.UTC(2024, 8, 24, 15, 0)); // 월은 0부터 시작하므로 4월이면 3으로 설정
  const endPromotionUtcDate = new Date(Date.UTC(2024, 9, 9, 15, 0)); // 월은 0부터 시작하므로 4월이면 3으로 설정
  const now = new Date();
  return now > startPromotionUtcDate && now < endPromotionUtcDate;
};

// 10월 하기스 가입 마케팅 프로모션 기간인지 확인하는 함수
// TODO: 일정 수정해야함
export const isShowJoinHuggiesGroupMarketingBanner = () => {
  // 한국시 9월 09일 00시 부터 9월 15일 24시까지 마케팅 프로모션 기간
  if (globalThis.appConfig.appEnv === 'alpha' || globalThis.appConfig.appEnv === 'dev') {
    return true;
  }
  const startPromotionUtcDate = new Date(Date.UTC(2024, 8, 24, 15, 0)); // 월은 0부터 시작하므로 4월이면 3으로 설정
  const endPromotionUtcDate = new Date(Date.UTC(2024, 9, 9, 15, 0)); // 월은 0부터 시작하므로 4월이면 3으로 설정
  const now = new Date();
  return now > startPromotionUtcDate && now < endPromotionUtcDate;
};
