import {
  AsyncBoundary,
  Divider,
  Spacing,
  ViewError,
  ViewLoader,
} from '@community-group/components';
import { ActivityComponentType } from '@stackflow/react';

import { useGetGroupMeetupDetail } from '@/api/hooks/useGetGroupMeetupDetail';
import { useEnterLocationEventLog } from '@/hooks/useEnterLocationEventLog';
import { useEnterTrackEvent } from '@/hooks/useEnterTrackEvent';
import { useHandleErrorWithThrowAccessStatusPage } from '@/hooks/useHandleErrorWithThrowAccessStatusPage';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';
import { useReferQueryParams } from '@/stackflow/hooks/useReferQueryParams';
import { PageParams } from '@/stackflow/types/params';
import { extendAppsflyerLoggerType } from '@/utils/analytics';
import { isNotMember } from '@/utils/role';

import { GroupMeetupDetailAppScreen } from '../components/GroupMeetupDetailAppScreen';
import GroupMeetupDetailCommentList from '../components/GroupMeetupDetailCommentList';
import { GroupMeetupInfoSection } from '../components/GroupMeetupInfoSection';
import { GroupMeetupMemberListSection } from '../components/GroupMeetupMemberListSection';
import { GroupSummarySection } from '../components/GroupSummarySection';
import RecommendGroupMeetupSection from '../components/RecommendGroupMeetupSection';
import { isCanceledGroupMeetup, isHostDeletedGroupMeetup } from '../utils/progressStatus';
import * as s from './index.css';
import { useFlow } from '@/stackflow';
import useCurrentGroupMe from '@/hooks/useCurrentGroupMe';
import { useHandleRestrictedUser } from '@/hooks/useHandleRestrictedUser';

type GroupMeetupDetailPageParams = Pick<PageParams, 'groupId' | 'meetupId' | 'from'>;

const GroupMeetupDetailPage: ActivityComponentType<GroupMeetupDetailPageParams> = () => {
  return (
    <GroupMeetupDetailAppScreen>
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
        <GroupMeetupDetailView />
      </AsyncBoundary>
    </GroupMeetupDetailAppScreen>
  );
};

const GroupMeetupDetailView = () => {
  const { groupId, meetupId } = usePathParams();
  const { from } = useQueryParams();

  const handleErrorWithThrowErrorStatusPage = useHandleErrorWithThrowAccessStatusPage();
  const { data: meetup } = useGetGroupMeetupDetail(
    { groupId, meetupId },
    {
      onError: (error) =>
        handleErrorWithThrowErrorStatusPage({
          error,
          serviceType: 'meetup',
          groupId,
          meetupId,
        }),
    }
  );

  useHandleRestrictedUser({
    serviceType: 'meetup',
    groupId,
    meetupId,
  });

  const isNotGroupMember = isNotMember(meetup.currentUserInfo.groupRole);

  // TODO: groupCategoryName groupInfo에 category 추가 요청
  const referParams = useReferQueryParams();

  useEnterTrackEvent({
    event: 'enter_group_meetup_detail',
    params: {
      ...referParams,
      groupId,
      meetupId,
      from,
      progressStatus: meetup?.progressStatus.status,
      meetupType: meetup?.meetupType,
      meetupHostId: meetup?.host?.userId,
      meetupTimeAt: meetup?.meetupTimeAt ?? '',
      meetupLocation: meetup?.poi?.name ?? meetup?.location ?? '',
      meetupHostRegionId: meetup?.host?.region.id,
      hasMeetupPoi: !!meetup?.poi?.name,
      currentParticipantsNumber: meetup?.currentParticipantsNumber,
      maximumParticipantsNumber: meetup?.maximumParticipantsNumber,
      isLikedGroupMeetup: meetup?.currentUserInfo.like, // 관심 등록 여부
      userId: meetup?.currentUserInfo.userId,
      userMeetupRole: meetup?.currentUserInfo.role,
      role: meetup?.currentUserInfo.groupRole,
    },
    sample: true,
    loggerType: extendAppsflyerLoggerType,
  });

  useEnterLocationEventLog({
    eventName: 'enter_group_meetup_detail',
    groupId: parseInt(groupId),
  });

  if (!meetup) return <ViewLoader />;

  if (isCanceledGroupMeetup(meetup.progressStatus.status) && meetup.currentUserInfo.role !== 'HOST')
    return <ViewError description="앗! 죄송해요.\n게시글이 삭제되어 볼 수 없어요." />;
  if (isHostDeletedGroupMeetup(meetup.progressStatus.status))
    return <ViewError description="앗! 죄송해요.\n게시글이 삭제되어 볼 수 없어요." />;

  return (
    <div className={s.Container}>
      <GroupMeetupInfoSection groupId={groupId} meetupId={meetupId} />
      <Divider padding={0} />
      <GroupMeetupMemberListSection groupId={groupId} meetupId={meetupId} />
      <GroupMeetupDetailCommentList
        groupId={groupId}
        meetupId={meetupId}
        isNotMember={isNotGroupMember}
        totalCommentCount={meetup.commentCount}
      />
      {isNotGroupMember && (
        <>
          <Divider padding={0} />
          <GroupSummarySection groupId={groupId} meetupId={meetupId} />
          <RecommendGroupMeetupSection />
        </>
      )}
      <Spacing height={80} />
    </div>
  );
};

export default GroupMeetupDetailPage;
