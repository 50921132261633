import { FeedbackRatingEnum } from '@community-group/api/lib/group/models';
import { Checkbox, Spacing, Typography } from '@community-group/components';
import { useMemo } from 'react';
import { Controller } from 'react-hook-form';

import { MentionMultilineTextField } from '@/components/common/MentionMultilineTextField';
import { GroupMeetupFormImagesField } from '@/components/group/Meetup/Form/components/Field/Images';
import { PhotoContextCheckbox } from '@/components/group/PostForm/components/PhotoContextCheckbox';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useMembersForMentions } from '@/hooks/useMembersForMentions';
import { useFlow } from '@/stackflow';

import { MeetupReviewFormHandler } from '../../MeetupReviewForm/type';
import * as s from './style.css';
type Props = {
  mentionMemberList: ReturnType<typeof useMembersForMentions>;
  selectedReviewRating?: FeedbackRatingEnum;
  formHandler: MeetupReviewFormHandler;
  shownPhotoContest?: boolean;
};

export const ReviewPostFields = ({
  mentionMemberList,
  selectedReviewRating,
  formHandler,
  shownPhotoContest,
}: Props) => {
  const { control, setValue, watch, register } = formHandler;
  const { push } = useFlow();
  const photoContestVersion = useFeatureFlag('photoContestVersion');

  const fieldLabel = useMemo(() => {
    if (selectedReviewRating === 'DISLIKE')
      return {
        title: '아쉬웠던 점을 당근팀에 알려주세요',
        description: '누구에게도 전달되지 않으니 안심하세요.',
      };

    return {
      title: '모임에서의 즐거웠던 순간을 기록해보세요',
      description: '남겨주신 후기는 모임 게시판에 공유돼요.',
    };
  }, [selectedReviewRating]);

  return (
    <div className={s.Wrapper}>
      <div className={s.Header}>
        <Typography typography="title2Bold" style={{ whiteSpace: 'pre-line' }} wordBreak="keep-all">
          {fieldLabel.title}
        </Typography>
        <Spacing height={12} />
        <Typography typography="bodyM1Regular" wordBreak="keep-all">
          {fieldLabel.description}
        </Typography>
      </div>
      <div>
        {selectedReviewRating === 'DISLIKE' ? (
          <Spacing height={24} />
        ) : (
          <Controller
            control={control}
            name="reviewPost.images"
            render={({ field: { onChange } }) => (
              <GroupMeetupFormImagesField
                name="reviewPost.images"
                setValue={(_, values) => {
                  onChange(values);
                }}
              />
            )}
          />
        )}

        <Controller
          control={control}
          name="reviewPost.content"
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            const errorMessage = error?.message ?? '';
            const ref = register('reviewPost.content').ref;
            return (
              <MentionMultilineTextField
                ref={ref}
                placeholder="내용을 작성해주세요. (선택)"
                name="reviewPost.content"
                isInvalid={!!errorMessage}
                errorMessage={errorMessage}
                value={value}
                onChange={(event) => {
                  const value = event.target.value;

                  onChange(value);
                }}
                mentionConfig={[
                  {
                    trigger: '@',
                    name: 'mentionedUserIds',
                    mentionList: mentionMemberList,
                    setMentionList: (currentMentionList: string[]) => {
                      setValue(
                        'reviewPost.mentionedUserIds',
                        currentMentionList.map((id) => parseInt(id))
                      );
                    },
                  },
                ]}
              />
            );
          }}
        />
        <div style={{ paddingTop: '16px' }}>
          {shownPhotoContest && selectedReviewRating !== 'DISLIKE' && (
            <Controller
              control={control}
              name="reviewPost.isPhotoContestEntry"
              render={({ field: { value, onChange } }) => {
                const handleOnChange = (isSelected: boolean) => {
                  onChange(isSelected);
                  if (isSelected && photoContestVersion === 3)
                    push('BottomSheet/RunningPhotoGuideBottomSheet', {});
                };
                return (
                  <PhotoContextCheckbox
                    isSelected={value ?? false}
                    onChange={handleOnChange}
                    disabled={watch('reviewPost.isGroupOnly') === true}
                  />
                );
              }}
            />
          )}
          {selectedReviewRating !== 'DISLIKE' && (
            <Controller
              control={control}
              name="reviewPost.isGroupOnly"
              render={({ field: { value, onChange } }) => (
                <Checkbox
                  size="large"
                  isSelected={value}
                  onChange={onChange}
                  isDisabled={watch('reviewPost.isPhotoContestEntry') === true}
                >
                  모임에만 공개
                </Checkbox>
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};
