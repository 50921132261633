import {
  AsyncBoundary,
  BoxButton,
  HelpBubbleTrigger,
  Image,
  Typography,
  useSnackbarAdapter,
  ViewError,
  ViewLoader,
} from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconHelpRegular, IconPhotoFill } from '@seed-design/icon';
import { ActivityComponentType } from '@stackflow/react';
import React, { useMemo } from 'react';

import { useGetGroupPhotoList } from '@/api/hooks/useGetGroupPhotoList';
import { usePostSetHeroImage } from '@/api/hooks/usePostSetHeroImage';
import EmptySection from '@/components/common/Empty';
import { useHandleErrorWithToast } from '@/hooks/useHandleErrorWithToast';
import { useStorage } from '@/hooks/useStorage';
import { useFlow } from '@/stackflow';
import { AppScreen } from '@/stackflow/components/AppScreen';
import { usePathParams } from '@/stackflow/hooks/usePathParams';
import { PageParams } from '@/stackflow/types/params';
import { trackEvent } from '@/utils/analytics';
import { refetchGroupDetail } from '@/utils/refetch/groupDetail';

import { GroupPhotoLoader } from '../../Detail/components/Photo/PhotoLoader';
import { getInfinitiveImagesFlatList } from '../../ImageViewerPage/utils/imageUtil';
import CameraSelectItem from '../components/CameraSelectItem';
import * as s from './GroupSettingHeroImageSelectorPage.css';

export type GroupSettingHeroImageSelectorPageParams = Pick<PageParams, 'groupId'>;

const GroupSettingHeroImageSelectorPage: ActivityComponentType<
  GroupSettingHeroImageSelectorPageParams
> = () => {
  const { groupId = '' } = usePathParams();
  const { data } = useGetGroupPhotoList({
    groupId,
    onlyPublic: true,
    onlyPinnable: true,
  });
  const photoList = useMemo(() => getInfinitiveImagesFlatList(data), [data]);
  const hasPhotoList = photoList.length > 0;
  const defaultSelectedPhotoIds = useMemo(
    () =>
      data?.pages
        .map((data) => data.photos)
        .flat(1)
        .filter((photo) => photo.isPinned)
        .map((photo) => photo.image.id) ?? [],
    [data?.pages]
  );

  const [selectedPhotoIds, setSelectedPhotoIds] = React.useState<string[]>(defaultSelectedPhotoIds);

  const handleErrorWithToast = useHandleErrorWithToast();
  const { pop } = useFlow();
  const snackbarAdapter = useSnackbarAdapter();
  const [showedHeroImageTooltip, setShowedHeroImageTooltip] = useStorage(
    'showedHeroImageTooltip',
    false
  );
  const { mutate, isLoading } = usePostSetHeroImage({
    onSuccess: () => {
      refetchGroupDetail({ groupId });
      snackbarAdapter.create({
        message: '배경사진을 설정했어요.',
        type: 'default',
        timeout: 3000,
        onClick: () => {
          snackbarAdapter.dismiss();
        },
      });
      pop();
    },
    onError: handleErrorWithToast,
  });

  const handleSubmit = () => {
    trackEvent({
      event: 'click_done_album_pin_page',
      params: {
        groupId,
        photoCount: selectedPhotoIds.length,
      },
    });
    mutate({
      groupId: Number(groupId),
      imageIds: selectedPhotoIds,
    });
  };

  return (
    <AppScreen
      appBar={{
        title: '배경사진 설정',
        renderRight: () => {
          return (
            <HelpBubbleTrigger
              title={`전체공개 글에 포함된 사진만\n선택할 수 있어요.`}
              onInteractOutside={() => {
                setShowedHeroImageTooltip(true);
              }}
              isOpen={!showedHeroImageTooltip}
              UNSAFE_style={{
                whiteSpace: 'pre-wrap',
              }}
              positioning={{
                placement: 'bottom-start',
              }}
              marginLeft={-14}
              onOpenChange={() => {
                setShowedHeroImageTooltip(true);
              }}
            >
              <IconHelpRegular
                style={{ padding: '0 .5rem' }}
                width={24}
                height={24}
                fill={vars.$scale.color.gray900}
                onClick={() => {
                  setShowedHeroImageTooltip(true);
                }}
              />
            </HelpBubbleTrigger>
          );
        },
      }}
      accessoryBar={
        hasPhotoList && (
          <div className={s.accessoryBarWrapper}>
            <BoxButton
              isLoading={isLoading}
              size="xlarge"
              width="100%"
              onClick={handleSubmit}
              isDisabled={isLoading}
            >
              완료
            </BoxButton>
          </div>
        )
      }
    >
      <AsyncBoundary pendingFallback={<ViewLoader />} rejectedFallback={<ViewError />}>
        <GroupSettingHeroImageSelectorPageInner
          selectedPhotoIds={selectedPhotoIds}
          setSelectedPhotoIds={setSelectedPhotoIds}
        />
      </AsyncBoundary>
    </AppScreen>
  );
};

const GroupSettingHeroImageSelectorPageInner = ({
  selectedPhotoIds,
  setSelectedPhotoIds,
}: {
  selectedPhotoIds: string[];
  setSelectedPhotoIds: (photoIds: string[]) => void;
}) => {
  const { push } = useFlow();
  const { groupId = '' } = usePathParams();
  const { data, hasNextPage, isFetchingNextPage } = useGetGroupPhotoList({
    groupId,
    onlyPublic: true,
    onlyPinnable: true,
  });
  const photoList = useMemo(() => getInfinitiveImagesFlatList(data), [data]);
  const hasPhotoList = photoList.length > 0;
  const snackbarAdapter = useSnackbarAdapter();

  const handlePhotoClick = async (photoId: string) => {
    if (selectedPhotoIds.includes(photoId)) {
      const tempSelectedPhotoIds = selectedPhotoIds.filter((id) => id !== photoId);
      setSelectedPhotoIds(tempSelectedPhotoIds);
      return;
    }
    if (selectedPhotoIds.length >= 6) {
      snackbarAdapter.dismiss();
      snackbarAdapter.create({
        message: '배경사진은 최대 6장까지 설정할 수 있어요.',
        type: 'default',
        timeout: 2000,
        onClick: () => {
          snackbarAdapter.dismiss();
        },
        shouldCloseOnAction: true,
      });
      return;
    }
    setSelectedPhotoIds([...selectedPhotoIds, photoId]);
  };

  const handleWriteNewPost = () => {
    push('GroupPostNewPage', { groupId, from: 'HeroImage' });
  };

  if (!hasPhotoList)
    return (
      <EmptySection className={s.EmptySectionWrapper}>
        <div className={s.EmptySectionTextWrapper}>
          전체공개로 작성된 사진이 없어요.
          <br />
          글을 작성하고 모임 사진을 올려보세요.
        </div>
        <BoxButton onClick={handleWriteNewPost} size="medium" variant="secondary" width="7.5rem">
          글쓰기
        </BoxButton>
      </EmptySection>
    );

  return (
    <>
      <ul className={s.photoListWrapper}>
        <li
          className={s.photoItem}
          key="add-hero-image-selector"
          onClick={() => {
            push('GroupPostNewPage', {
              groupId,
              from: 'HeroImage',
            });
          }}
        >
          <div className={s.HeroImageSelectBox}>
            <IconPhotoFill size={24} color={vars.$static.color.staticWhite} />
            <Typography typography="label4Regular" color="staticWhite">
              사진 추가
            </Typography>
          </div>
        </li>
        {photoList.map(({ image }, idx) => {
          const selectedIndex = selectedPhotoIds.indexOf(image.id);
          return (
            <li
              className={s.photoItem}
              key={idx}
              onClick={() => {
                handlePhotoClick(image.id);
              }}
            >
              <div className={s.SelectedBoxBorder({ selected: selectedIndex !== -1 })}></div>
              <div className={s.photo}>
                <CameraSelectItem selectedIndex={selectedIndex} />
                <Image
                  className="real-image"
                  width={'100%'}
                  height={'100%'}
                  src={image.small}
                ></Image>
              </div>
            </li>
          );
        })}
      </ul>
      {hasNextPage && !isFetchingNextPage && (
        <GroupPhotoLoader params={{ groupId, onlyPublic: true, onlyPinnable: true }} />
      )}
    </>
  );
};

export default GroupSettingHeroImageSelectorPage;
