import { StoreSlice } from '..';

export type GlobalStateSlice = {
  fixedLayout: boolean;
  profileCheckPending: boolean;
  setFixedLayout: (value: boolean) => void;
  setProfileCheckPending: (value: boolean) => void;

  isHuggiesMarketingJoinable: boolean; // 10월 허기스 가입 사전신청 이벤트 참가 가능 여부, 프로모션 종료 후 제거 가능
  setHuggiesMarketingJoinable: (value: boolean) => void;
  isHuggiesMarketingCreate: boolean; // 10월 허기스 생성, 프로모션 종료 후 제거 가능
  setHuggiesMarketingCreate: (value: boolean) => void;
};

export const createGlobalStateSlice: StoreSlice<GlobalStateSlice> = (set) => ({
  fixedLayout: true,
  profileCheckPending: false,

  // TODO: 허기스 마케팅 프로모션 관련 스토어 추가 이후 삭제
  isHuggiesMarketingJoinable: false,
  setHuggiesMarketingJoinable: (value) => {
    set((state) => ({ ...state, isHuggiesMarketingJoinable: value }));
  },
  isHuggiesMarketingCreate: false,
  setHuggiesMarketingCreate: (value) => {
    set((state) => ({ ...state, isHuggiesMarketingCreate: value }));
  },
  setFixedLayout: (value) => {
    set((state) => ({ ...state, fixedLayout: value }));
  },
  setProfileCheckPending: (value) => {
    set((state) => ({ ...state, profileCheckPending: value }));
  },
});
