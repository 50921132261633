import { groupClient } from '@community-group/api';

import { getGroupPath } from '@/api/base/group';
import { getPostPath } from '@/api/base/post';
import { USER_ME_URL } from '@/api/base/user';
import { getOpenEventPath } from '@/api/hooks/event/common/useGetOpenEvent';
import { getGroupDetailPath } from '@/api/hooks/useGetGroupDetail';
import { getGroupFeedListPath } from '@/api/hooks/useGetGroupFeedList';
import { getGroupFeedReviewWriteableMeetupPath } from '@/api/hooks/useGetGroupFeedReviewWriteableMeetup';
import { getGroupFeedSummaryBulletin } from '@/api/hooks/useGetGroupFeedSummaryBulletin';
import { getGroupFeedUpcomingMeetupCount } from '@/api/hooks/useGetGroupFeedUpcomingMeetupCount';
import { getGroupMePath } from '@/api/hooks/useGetGroupMe';
import { getGroupPhotoListPath } from '@/api/hooks/useGetGroupPhotoList';
import { getMeetupListPath } from '@/api/hooks/useGetMeetupList';
import { getMeetupReviewInfoPath } from '@/api/hooks/useGetMeetupReviewInfo';
import { getMemberMissionListPath } from '@/api/hooks/useGetMemberMissionList';
import { getNoticesPath } from '@/api/hooks/useGetNotices';
import { getRunningGrowthMissionPath } from '@/api/hooks/useGetRunningGrowthMission';
import { getSuperHostOnBoardingMissionPath } from '@/api/hooks/useGetSuperHostOnBoardingMission';
import { queryClient } from '@/api/instance';

type Params = {
  groupId: string;
};

/*
 *  모임 상세 페이지에서 사용하는 쿼리를 한번에 리페치하는 함수
 */
export const refetchGroupDetail = ({ groupId }: Params) => {
  const numberGroupId = Number(groupId);
  queryClient.refetchQueries([getGroupPath(groupId)]);
  queryClient.refetchQueries([getGroupPhotoListPath(groupId)]);
  queryClient.refetchQueries([getGroupPhotoListPath(groupId), true]);
  queryClient.refetchQueries([getMeetupReviewInfoPath(groupId)]);
  queryClient.refetchQueries([getPostPath(groupId)]);
  queryClient.refetchQueries([getGroupDetailPath(groupId)]);
  queryClient.refetchQueries([getGroupMePath(groupId)]);
  queryClient.refetchQueries([getMeetupListPath(groupId)]);
  queryClient.refetchQueries([getGroupFeedReviewWriteableMeetupPath(numberGroupId)]);
  queryClient.refetchQueries([
    groupClient.api.PostBoardApi.getapiV1GroupsIdPostsBoardCategoriesGetPath(numberGroupId),
  ]);
  queryClient.refetchQueries([getNoticesPath(numberGroupId)]);
  queryClient.refetchQueries([getOpenEventPath()]);
  queryClient.refetchQueries([USER_ME_URL]);
  queryClient.refetchQueries([getMemberMissionListPath(numberGroupId)]);
  queryClient.refetchQueries([getSuperHostOnBoardingMissionPath(numberGroupId)]);
  queryClient.refetchQueries([getRunningGrowthMissionPath(numberGroupId)]);
  queryClient.refetchQueries([getGroupFeedListPath(groupId)]);
  queryClient.refetchQueries([getGroupFeedSummaryBulletin(numberGroupId)]);
  queryClient.refetchQueries([getGroupFeedUpcomingMeetupCount(numberGroupId)]);
};
